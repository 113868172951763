// common_icon 형태로 재사용가능하게 관리할 아이콘 목록
$icons:
arrow_down
arrow_left
arrow_right
arrow_up
calendar
close
download_white
facebook_gray
instagram_gray
rank_down
rank_up
schedule_empty
search
share
tooltip
twitter_gray
url
info
versus
naverblog_gray
youtube_gray
;

.common_icon {
  display: inline-flex;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  &::after {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: center / 100% no-repeat;
    content: '';
  }
  &.ty_xs {
    width: 16px;
    height: 16px;
  }
  &.ty_sm {
    width: 24px;
    height: 24px;
  }
  &.ty_md {
    width: 32px;
    height: 32px;
  }
  &.ty_lg {
    width: 46px;
    height: 46px;
  }
  &.ty_xl {
    width: 72px;
    height: 72px;
  }
}
@each $icon in $icons {
  .icon_#{$icon}::after {
    background-image: svg-load("../../img/svg/icons/#{$icon}.svg");
  }
}
.icon_facebook::after {
  background-image: svg-load("../../img/svg/icons/facebook_round.svg");
}
.icon_twitter::after {
  background-image: svg-load("../../img/svg/icons/twitter_round.svg");
}
// arrow btn disabled
button:disabled {
  .icon_arrow_left::after,
  .icon_arrow_right::after {
    @include color-filter(map-get($color-type, gray-02));
  }
}

.icon_arrow_up.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_up_16.svg");
}
.icon_arrow_down.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_down_16.svg");
}
.icon_arrow_left.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_left_16.svg");
}
.icon_arrow_right.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_right_16.svg");
}