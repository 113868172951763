// 국문
@font-face {
  font-family: 'Notosans';
  font-weight: 700;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/NotoSans-Bold.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/NotoSans-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Notosans';
  font-weight: 500;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/NotoSans-Medium.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/NotoSans-Medium.woff) format('woff');
}
@font-face {
  font-family: 'Notosans';
  font-weight: 400;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/NotoSans-Regular.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/NotoSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
	font-weight: 300;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Light.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Light.woff) format("woff2");
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 400;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Regular.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Regular.woff2) format("woff2");
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 500;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Medium.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Medium.woff2) format("woff2");
}
@font-face {
  font-family: 'Pretendard';
	font-weight: 600;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-SemiBold.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-SemiBold.woff2) format("woff2");
}
@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
  src: url("//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Bold.woff") format("woff");
	src: url("//tketlink.dn.toastoven.net/markup_resources/font/Pretendard-Bold.woff2") format("woff2");
}


@font-face {
  font-family: 'PretendardJP';
	font-weight: 300;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Light.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Light.woff) format("woff2");
}
@font-face {
  font-family: 'PretendardJP';
	font-weight: 400;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Regular.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Regular.woff2) format("woff2");
}
@font-face {
  font-family: 'PretendardJP';
	font-weight: 500;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Medium.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Medium.woff2) format("woff2");
}
@font-face {
  font-family: 'PretendardJP';
	font-weight: 600;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-SemiBold.woff) format("woff");
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-SemiBold.woff2) format("woff2");
}
@font-face {
	font-family: 'PretendardJP';
	font-weight: 700;
  src: url("//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Bold.woff") format("woff");
	src: url("//tketlink.dn.toastoven.net/markup_resources/font/PretendardJP-Bold.woff2") format("woff2");
}

// 영문+숫자 단독
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Bold.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Medium.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Medium.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Regular.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Light.woff2) format('woff2'), 
       url(//tketlink.dn.toastoven.net/markup_resources/font/Roboto-Light.woff) format('woff');
}

// 링크온
@font-face {
	font-family: 'S-CoreDream-3Light';
	src:url(//tketlink.dn.toastoven.net/markup_resources/font/scdream3-webfont.woff) format('woff'); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-4Regular';
	src:url(//tketlink.dn.toastoven.net/markup_resources/font/scdream4-webfont.woff) format('woff'), /* Modern Browsers */
		url(//tketlink.dn.toastoven.net/markup_resources/font/scdream4-webfont.ttf) format('truetype'); /* Safari, Android, iOS */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'S-CoreDream-5Regular';
	src:url(//tketlink.dn.toastoven.net/markup_resources/font/scdream5-webfont.woff) format('woff'), /* Modern Browsers */
		url(//tketlink.dn.toastoven.net/markup_resources/font/scdream5-webfont.ttf) format('truetype'); /* Safari, Android, iOS */
	font-weight: normal;
	font-style: normal;
}

// 기획전
@font-face {
	font-family: 'ONE-Mobile-Title';
	font-weight: 400;
	src: url(//tketlink.dn.toastoven.net/markup_resources/font/ONE-Mobile-Title.woff) format('woff'),
		url(//tketlink.dn.toastoven.net/markup_resources/font/ONE-Mobile-Title.woff2) format('woff2');
}