.cs_center {
  padding: 50px 0 205px;
  background: #f8f9fa;
  .social {
    position: absolute;
    top: 29px;
    right: 20px;
    border: 1px solid #dcdde1;
    border-radius: 2px;
    color: $color-black;
    font-size: 0;
    background: $color-white;
    li {
      display: inline-block;
      a {
        display: flex;
        width: 30px;
        height: 30px;
        text-align: center;
        &::before {
          margin: auto;
          background-repeat: no-repeat;
        }
      }
      & + li {
        border-left: 1px solid #dcdde1;
      }
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
  }
}

/* 검색창 */
.search_help {
  margin-bottom: 10px;
  padding: 19px 40px;
  background: #ecedf2;
  &.faq_search {
    padding: 42px 61px;
  }
  fieldset {
    display: flex;
    align-items: center;
  }
  label {
    margin-right: 22px;
    color: $color-black;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .selectbox_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 36px;
  }
  .selectbox {
    width: 224px;
    margin-right: 5px;
    .select {
      padding-top: 8px;
      padding-bottom: 8px;
      height: 38px;
    }
    .select_list {
      max-height: 169px;
    }
  }
  input {
    flex: 1;
    height: 36px;
    padding: 0 20px;
    border: 0;
    box-sizing: border-box;
    color: $color-black;
    font-size: 14px;
    line-height: 36px;
    background: $color-white;
    &::placeholder {
      color: #a7acb6;
    }
  }
  .btn_search {
    display: inline-flex;
    margin: 0 3px 0 2px;
    width: 39px;
    height: 36px;
    background: $color-black;
    &::after {
      display: block;
      margin: auto;
      width: 24px;
      height: 24px;
      background: svg-load('../../img/svg/icons/search_w.svg') 50%/100% no-repeat;
      content: "";
    }
    &:hover {
      background: #000;
    }
  }
  .btn_full {
    margin-left: 5px;
    width: 94px;
    height: 36px;
  }
}

/* 서비스센터 홈 */
.help_link {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  overflow: hidden;
  margin-top: 30px;
  li {
    flex: 0 0 auto;
    .help_box {
      display: flex;
      flex-flow: column wrap;
      position: relative;
      padding: 20px;
      width: 246px;
      height: 185px;
      box-sizing: border-box;
      background: #f5f6f9;
      &:after {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 72px;
        height: 72px;
        background-repeat: no-repeat;
        content: "";
      }
    }

    &:nth-child(1) .help_box:after {
      background-image: svg-load('../../img/svg/icons/help_guide.svg');
    }
    &:nth-child(2) .help_box:after {
      background-image: svg-load('../../img/svg/icons/help_consult.svg');
    }
    &:nth-child(3) .help_box:after {
      background-image: svg-load('../../img/svg/icons/help_notice.svg');
    }
    &:nth-child(4) .help_box:after {
      background-image: svg-load('../../img/svg/icons/help_notice.svg');
    }
    &:nth-child(5) .help_box:after {
      background-image: svg-load('../../img/svg/icons/help_personal.svg');
    }
    &:nth-child(6) .help_box:after {
      background-image: svg-load('../../img/svg/icons/help_inquiry.svg');
    }
  }
  .link_title {
    display: inline-flex;
    position: relative;
    margin-right: auto;
    padding-right: 24px;
    color: $color-black;
    font-size: 20px;
    line-height: 26px;
    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 16px;
      height: 16px;
      background: svg-load('../../img/svg/icons/arrow_right_16.svg') no-repeat;
      transform: translateY(-50%);
      content: "";
    }
  }
  .link_text {
    display: block;
    margin: auto 0 8px;
    color: #878d95;
    font-size: 13px;
    line-height: 18px;
  }
  .desc_phone {
    display: block;
    color: $color-black;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.04em;
  }
}

/* 게시글 */
.list_view {
  overflow: hidden;
  position: relative;
  margin-top: 36px;
  border-top: 1px solid #cfd0d7;
  border-bottom: 1px solid #ecedf2;
  
  .th_info {
    position: relative;
    padding: 25px 20px 25px 171px;
    min-height: 196px;
    box-sizing: border-box;
    color: $color-black;
    font-size: 14px;
    line-height: 22px;
    background: #f8f9fa;
    dt {
      float: left;
      min-width: 105px;
      padding-right: 10px;
      font-weight: 700;
    }
    dd {
      overflow: hidden;
    }
    .thumb {
      position: absolute;
      top: 30px;
      left: 20px;
      width: 111px;
      height: 136px;
    }
    .title {
      margin-bottom: 8px;
      max-width: 450px;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -1px;
    }
    .common_btn {
      margin-top: 10px;
      width: 110px;
    }
  }
  .th {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0 10px 20px;
    width: 105px;
    height: 64px;
    border-bottom: 1px solid #ecedf2;
    box-sizing: border-box;
    color: $color-black;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
  }
  .list_title{
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 115px;
    height: 64px;
    border-bottom: 1px solid #ecedf2;
    box-sizing: border-box;
    color: $color-black;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.04em;
  }
  .list_date {
    padding: 9px 0 8px 18px;
    font-size: 12px;
    color: #878d95;
    border-bottom: 1px solid #ecedf2;
    line-height: 13px;
    .bar{margin: 0 4px}
  }
  .list_cont{
    color: $color-black;
    padding: 30px 20px;
    line-height: 16px;
    font-size: 14px;
  }
}

/* GUIDE */
.reserve_guide {
  margin-top: 46px;
  padding-bottom: 79px;
  .clear_wrap {
    .fl {
      width: 52%;
      em {
        color: $color-primary;
        font-weight: 700;
      }
    }
    .btn_area {
      float: left;
      padding-top: 20px;
      width: 55%;
      font-size: 0;
      .btn_full {
        &:first-child {
          margin-left: 0;
        }
        &+.btn_full {
          margin-left: 6px;
        }
      }
    }
  }
}
/* How to Sign Up */
.reserve_guide {
  .section {
    position: relative;
    &+.section {
      margin-top: 90px;
    }
    &_dsc_area {
      display: inline-block;
      max-width: 314px;
      vertical-align: top;
      box-sizing: border-box;
      .guide_tit {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        letter-spacing: -.2px;
      }
      .dsc_lst {
        margin-top: 10px;
        li {
          position: relative;
          font-size: 14px;
          padding-left: 7px;
          margin-top: 5px;
          color: #666;
          line-height: 20px;
          letter-spacing: -.3px;
          &:before {
            position: absolute;
            top: 8px;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: #707070;
            content: "";
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    &_img_area {
      float: right;
    }
  }
}
/* How to Book */
.reserve_guide {
  .global_ticket_guide {
    margin-bottom: 14px;
    color: $color-black;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
  }
  .guide_dsc {
    margin-top: 7px;
    color: $color-black;
    font-size: 14px;
    line-height: 20px;
  }
  .guide_step_title {
    display: inline-block;
    margin-top: 46px;
    font-size: 20px;
    line-height: 24px;
  }
  .step_item {
    &:first-child .step_number {
      margin-top: 30px;
    }
    &:nth-child(5) {
      .guide_img_wrap {
        width: 449px;
      }
    }
  }
  .step_tit_box {
    color: $color-gray;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.01em;
    .step_number {
      display: block;
      margin-top: 69px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: $color-primary;
    }
    .step_contit {
      display: block;
      margin-top: 8px;
      color: $color-black;
      font-size: 14px;
      line-height: 21px;
    }
    .logo_payco {
      display: inline-block;
      overflow: hidden;
      margin-top: 2px;
      width: 65px;
      height: 18px;
      vertical-align: top;
      text-indent: -9999px;
      background: svg-load('../../img/svg/common/logo_payco.svg') no-repeat;
      background-size: 65px auto;
    }
  }
  .step_tit_sub {
    color: $color-gray;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.5px;
  }
  .guide_img_wrap {
    position: relative;
    margin-top: 31px;
    img {
      display: block;
      width: 100%;
    }
    .dsc2 {
      margin-left: 14px;
      color: $color-gray;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.5px;
      text-indent: -14px;
      .step_con_subtit {
        font-size: 14px;
      }
    }
  }
  .step_contents {
    .guide_img_wrap {
      margin-top: 47px;
      &:first-child {
        width: 536px;
        & + .guide_img_wrap img {
          margin-top: 20px;
        }
      }
      img {
        margin-top: 14px;
      }
    }
  }
  .reserve_step {
    overflow: hidden;
    > li {
      padding: 26px 0 28px;
      border-top: 1px dashed #ecedf2;
      &:first-child {
        padding-top: 0;
        border: 0;
      }
      .bx_left {
        float: left;
        padding-top: 4px;
        width: 163px;
        box-sizing: border-box;
      }
      .bx_right {
        overflow: hidden;
      }
      &:after {
        display: block;
        clear: both;
        content: "";
      }
    }
    .step_num {
      display: block;
      color: $color-primary;
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
    }
    .step_title {
      color: $color-black;
      font-size: 14px;
      font-weight: 700;
      line-height: 15px;
    }
    .bx_right {
      p {
        font-size: 14px;
        line-height: 20px;
      }
      .dsc_list + p {
        margin-top: 14px;
      }
      .first_notice {
        display: block;
        position: relative;
        margin-top: 15px;
        padding-left: 22px;
        font-weight: bold;
        font-size: 12px;
        color: $color-gray;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 16px;
          height: 16px;
          background: svg-load("../../img/svg/icons/info.svg") 50%/100% no-repeat;
          content: "";
        }
      }
    }
    .first_notice + .dsc_list {
      margin-top: 11px;
    }
    .dsc_list {
      margin-top: 4px;
      li {
        position: relative;
        padding-left: 5px;
        font-size: 12px;
        color: #878d95;
        letter-spacing: -1px;
        &+li {
          margin-top: 7px;
        }
        &:before {
          position: absolute;
          top: 6px;
          left: 0;
          width: 2px;
          height: 2px;
          background: #878d95;
          content: "";
          border-radius: 50%;
        }
      }
    }

  }
}
/* Ticket Pickup */
.info_memlist_block {
  margin-top: 28px;
  font-size: 14px;
  &.dotline{
    padding-bottom: 24px;
    border-bottom: 1px dashed #ecedf2;
  }
  .color_point {
    font-weight:bold;
  }
  strong {
    color: $color-black;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -1px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
  }
  a {
    color: $color-primary;
    text-decoration: underline;
    line-height: 24px;
  }
  .dsc {
    margin-top: 10px;
    &+.dsc {
      margin-top: 0;
    }
    dt {
      position: relative;
      margin-top: 10px;
      padding-left: 11px;
      font-size: 14px;
      line-height: 22px;
      &:first-child {
        margin-top: 0;
      }
      &:before {
        position: absolute;
        top: 11px;
        left: 3px;
        width: 2px;
        height: 2px;
        background: $color-black;
        content: "";
      }
    }
  }
  .inner_lst {
    li {
      padding-left: 21px;
      color: $color-gray;
      font-size: 14px;
      line-height: 22px;
      text-indent: -6px;
    }
    dt, dd {
      line-height: 22px;
    }
    &.type2 li {
      padding-left: 11px;
    }
  }
  .bx_img {
    display: block;
    margin-top: 28px;
  }
  .btn_link {
    margin-left: 6px;
    font-weight: 400;
    font-size: 12px;
    vertical-align: bottom;
  }
}