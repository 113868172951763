body {
  &.no-scroll {
    overflow: hidden;
  }
}
.common_wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1180px;
  min-height: 100%;
}
.common_container {
  flex: 1;
  padding-bottom: 160px;
  // Old Page Layout
  .inner {
    padding-top: 50px;
  }
}
.common_section,
.common_inner {
  margin: 0 auto;
  width: $content-width;
}

.btn_global_guide {
  position: absolute;
  bottom: 0;
  right: 50px;
  padding: 0 12px;
  min-width: 110px;
  height: 40px;
  border-radius: 40px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  background: #242428;
  transition: right .3s ease-out .2s;
  .btn_move_top.is-active + & {
    right: 100px;
    transition: none;
  }

}
.btn_move_top {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  right: 50px;
  width: 40px;
  height: 40px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 50%;
  background: rgba($color: $color-white, $alpha: 0.95);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  z-index: map-get($z-index, popup);
  &::before {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    background: svg-load('../../img/svg/icons/move_top.svg') center / 24px no-repeat ;
    content: '';
  }
  &:hover,
  &:focus {
    border-color: $color-black;
    background-color: $color-black;
    &::before {
      @include color-filter($color-white);
    }
  }
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}
.btn_page_refresh {
  display: none;
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 50%;
  background: rgba($color: $color-white, $alpha: 0.95);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  z-index: map-get($z-index, popup);
  &::before {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    background: svg-load('../../img/svg/icons/refresh.svg') center / 24px no-repeat ;
    content: '';
  }
  &:hover {
    border-color: $color-black;
    background-color: $color-black;
    &::before {
      @include color-filter($color-white);
    }
  }
}
.floating_btns {
  position: fixed;
  right: 0;
  bottom: 20px;
  height: 0;
  z-index: 900;
  transition: bottom .5s ease;
  @media (max-width:1540px) {
    right: 50%;
    margin-right: -730px;
    .btn_global_guide,
    .btn_page_refresh,
    .btn_move_top {
      right: 50px;
    }
  }
  @media (max-width:1474px) {
    &.is-stuck {
      right: 0;
      margin-right: 0;
    }
  }
  .bottom_popup.open + & {
    bottom: 100px;
    &.is-stuck {
      margin-bottom: 184px;
    }
  }
  &.is-stuck {
    position: absolute;
    margin-bottom: 264px;
    transition: none;
  }
}
.bottom_popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  min-width: 1120px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: rgba(0,0,0,.9);
  opacity: 0;
  transform: translateY(100%);
  transition: opacity .25s, transform .5s ease;
  .common_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
  }
  .text {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
  }
  &_close {
    position: absolute;
    top: 50%;
    right: 55px;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    &:before, 
    &:after {
      position: absolute;
      top: 1px;
      left: 14px;
      width: 2px;
      height: 29px;
      border-radius: 4px;
      background: #fff;
      content: "";
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &.open {
    opacity: 1;
    transform: translateY(0);
  }
}