.fl {float:left !important}
.fr {float:right !important}
.tc {text-align:center !important}
.tl {text-align:left !important}
.tr {text-align:right !important}
.elp {display:block;overflow:hidden;width:100%;white-space:nowrap;text-overflow:ellipsis}
.fbold {font-weight:700}
.lspacing0 {letter-spacing:0}
.lspacingm1 {letter-spacing:-1px}
.color_black {color:#242428 !important}
.color_point {color:#fa2828 !important}
.color_gray {color:#62676c !important}
.color_gray2 {color:#878d95 !important}
.color_gray3 {color:#a7acb6 !important}
.fs12 {font-size:12px !important}
.fs12_v2 {font-size:12px !important;letter-spacing:-.5px}
.fs16 {font-size:16px !important}
.number {font-family:'Roboto',-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,'Malgun Gothic',Arial,sans-serif}
.fwn {font-weight:normal}
.p_reative {position:relative}
.textarea {border:1px solid #e8eaf1;padding:15px 20px;line-height:22px;color:#242428}
.tindent8 {text-indent:-5px}
.tindent11 {text-indent:-11px}
.vat {vertical-align:top}
.vam {vertical-align:middle !important}
.lineh20 {line-height:20px !important}
.bdnone {border:0 !important}
.bgnone {background:none !important}
.link_point {color:#fa2828 !important;text-decoration:underline !important}
.mgt0 {margin-top:0 !important}
.mgt7 {margin-top:7px !important}
.mgt10 {margin-top:10px !important}
.mgt15 {margin-top:15px !important}
.mgt20 {margin-top:20px !important}
.mgt30 {margin-top:30px !important}
.mgt40 {margin-top:40px !important}
.mgt60 {margin-top:60px !important}
.mgtl35 {margin-top:35px !important}
.mgb10 {margin-bottom:10px !important}
.mgb30 {margin-bottom:30px !important}
.mgbm24 {margin-bottom:-24px !important}
.mgl20 {margin-left:20px !important}
.mgr10 {margin-right:10px !important}
.pdb30 {padding-bottom:30px !important}
.pdl5 {padding-left:5px !important}
.pdl10 {padding-left:10px !important}
.no_scroll {overflow-y:scroll;position:fixed;width:100%;height:100%}
.no_data {text-align:center;color:$color-black}
.inner {position:relative;margin:0 auto;width:1120px}
.clear_wrap:after {display:block;clear:both;content:""}