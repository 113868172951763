.product_grid {
  &_list {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 35px;
  }
  &_item {
    width: 350px;
    &.type_loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .refresh_loading {
        margin: 0;
      }
    }
  }

  &_unit {
    .product_link {
      display: block;
      &:hover,
      &:focus {
        .product_img {
          transform: scale(1.1);
        }
      }
    }
    .product_imgbox {
      overflow: hidden;
      position: relative;
      z-index: 0;
      width: 100%;
      border-radius: 10px;
      background: url(../img/svg/common/img_default.svg) center / 54.5% no-repeat map-get($color-background, bg-01);
      @include img-border;
      .product_img {
        width: 100%;
        height: 100%;
        transition: transform .2s cubic-bezier(.165,.84,.44,1);
      }
    }
    .product_banner_imgbox {
      .product_banner_img {
        width: 100%;
        height: 100%;
      }
    }
    .product_info {
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    .product_region {
      color: #FA2828;
      font-size: 16px;
      line-height:22px;
    }
    .product_title {
      display: block;
      font-weight: 600;;
      font-size: 24px;
      line-height: 30px;
      color: $color-black;
    }
    .product_sideinfo {
      margin-top: 6px;
      color: $color-gray;
    }
    .product_place {
      display: block;
    }
    .product_period {
      display: block;
      margin-top: 6px;
      line-height: 20px;
    }
    .product_flag {
      margin-top: 10px;
    }
    .product_discount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    .product_discount.payco_disco {
      align-items: flex-end;
      height:35px;
      margin-top:12px;
    }
    .product_discount.payco_disco .product_discount_ratio {
      height: 31.5px;
    }
    .product_discount_ratio {
      color: $color-primary;
    }
    .product_discount_number {
      @include font-family('Roboto');
      font-size: 40px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.04em;
    }
    .product_discount_text {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }
    .product_price {
      line-height: 0;
      text-align: right;
    }
    .product_origin_price {
      display: block;
      font-size: 0;
      color: map-get($color-type, gray-01);
      text-decoration: none;
      .product_price_number {
        @include font-family('Roboto');
        font-size: 14px;
        line-height: 14px;
      }
      .product_price_text {
        font-size: 13px;
        line-height: 13px;
      }
    }
    .product_discount_price {
      display: block;
      font-size: 0;
      color: $color-black;
      .product_price_number {
        @include font-family('Roboto');
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.04em;
      }
      .product_price_text {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}