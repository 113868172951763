.checkbox_item,
.radio_item {
  display: block;
  font-size: 0;
  line-height: 0;
}
.input_checkbox,
.input_radio {
  @include blind;
}
.label_checkbox, 
.label_radio {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  font-size: 14px;
  line-height: 18px;
  color: $color-black;
  vertical-align: middle;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid map-get($color-type, gray-01);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-white;
    box-sizing: border-box;
    content: '';
    .input_checkbox:checked + &,
    .input_radio:checked + & {
      border-color: $color-black;
      background-color: $color-black;
    }
    .input_checkbox:disabled + &,
    .input_radio:disabled + & {
      border-color: map-get($color-line, line-04);
      background-color: map-get($color-background, bg-01);
    }
  }
  .input_checkbox:disabled + &,
  .input_radio:disabled + & {
    color: map-get($color-type, gray-02);
  }
  .text_primary {
    color:  $color-primary;
  }
}
.label_checkbox {
  &::before {
    .input_checkbox:checked + & {
      background-size: 12px 12px;
      background-image: svg-load('../../img/svg/icons/checkbox_inner.svg', fill=$color-white);
    }
    .input_checkbox:checked:disabled + & {
      background-image: svg-load('../../img/svg/icons/checkbox_inner.svg', fill=map-get($color-type, gray-02));
    }
  }  
}
.label_radio {
  &::before {
    .input_radio:checked + & {
      background-size: 8px 8px;
      background-image: svg-load('../../img/svg/icons/radio_inner.svg', fill=$color-white);
    }
    .input_radio:checked:disabled + & {
      background-image: svg-load('../../img/svg/icons/radio_inner.svg', fill=map-get($color-type, gray-02));
    }
  }  
}
.checkbox_mark {
  display: block;
  font-size: 0;
  line-height: 0;
  .label_checkbox {
    padding-left: 32px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    &::before {
      width: 24px;
      height: 24px;
      border-color: #DCDDE1;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 4.29289C14.0976 4.68342 14.0976 5.31658 13.7071 5.70711L7 12.4142L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289C2.68342 5.90237 3.31658 5.90237 3.70711 6.29289L7 9.58579L12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289Z' fill='%23D5D6D9'/%3E%3C/svg%3E%0A");
    }
  }
  .input_checkbox:checked{
    &+.label_checkbox::before {
      background-size: 16px 16px;
    }
  }
}

.checkbox,
.ipt_chk {
  display: inline-flex;
  align-items: center;
  input {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid map-get($color-type, gray-01);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-white;
  }
  label {
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    line-height: 18px;
    color: $color-black;
  }
  &.checked input,
  input:checked {
    border-color: $color-black;
    background-color: $color-black;
    background-size: 12px 12px;
    background-image: svg-load('../../img/svg/icons/checkbox_inner.svg', fill=$color-white);
  }
  &.disabled input,
  input:disabled {
    border-color: map-get($color-line, line-04);
    background-color: map-get($color-background, bg-01);
  }
  &.checked.disabled input,
  input:checked:disabled {
    background-image: svg-load('../../img/svg/icons/checkbox_inner.svg', fill=map-get($color-type, gray-02));
  }
}
.radio,
.ipt_radio {
  display: inline-flex;
  align-items: center;
  input {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid map-get($color-type, gray-01);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-white;
  }
  label {
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    line-height: 18px;
    color: $color-black;
  }
  &.checked input,
  input:checked {
    border-color: $color-black;
    background-color: $color-black;
    background-size: 8px 8px;
    background-image: svg-load('../../img/svg/icons/radio_inner.svg', fill=$color-white);
  }
  &.disabled input,
  input:disabled {
    border-color: map-get($color-line, line-04);
    background-color: map-get($color-background, bg-01);
  }
  &.checked.disabled input,
  input:checked:disabled {
    background-image: svg-load('../../img/svg/icons/radio_inner.svg', fill=map-get($color-type, gray-02));
  }
}