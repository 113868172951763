/* e sports */
.common_container.submain_front {
  background: #f8f9fa;
  h3.suggest {
    margin: 39px 0 20px;
    font-weight :500;
    font-size: 26px;
    line-height: 26px;
  }
  .depth3 {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    width: 1120px;
    margin: 0 auto;
    border: 1px solid map-get($color-line, line-01);
    background: $color-white;
    li {
      width: 20%;
      border-right: 1px solid map-get($color-line, line-01);
      box-sizing: border-box;
      &:nth-child(5n) {
        border-right: 0;
      }
      &:nth-child(5n+1) {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background: map-get($color-line, line-01);
          content: '';
        }
        &:first-child::before {
          display: none;
        }
      }
      a {
        display: block;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        color: $color-black;
      }
      &.on a {
        background: $color-black;
        color: $color-white;
      }
    }
  }
  .location_path {
    padding-top: 20px;
    line-height: 31px;
    font-size: 14px;
  }
  .ban_area {
    margin-top: 10px;
    img {
      width: 1120px;
      height: 317px;
    }
  }
}
.top_area {
  padding: 20px 0 39px;
  .submain_ad {
    overflow: hidden;
    border: 1px solid map-get($color-line, line-01);
    border-radius: 2px;
    li {
      float: left;
      border-left: 1px solid map-get($color-line, line-01);
      height: 108px;
      &:first-child {
        border: 0;
        width: 670px;
        overflow: hidden;
        position: relative;
        border-radius: 2px 0 0 2px;
      }
    }
    a {
      display: block;
    }
  }
  .submain_topban {
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
  }
  .submain_goods {
    position: relative;
    width: 759px;
    height: 317px;
    overflow: hidden;
    float: left;
    border-radius: 2px 0 0 2px;

    ul {
      overflow: hidden;
    }

    li {
      float: left;
      position: absolute;
      left: -9999px;
      top: 0;
      &:first-child {
        margin: 0;
      }
      &.last {
        margin-top: 1px;
      }
      a {
        display: block;
        font-size: 0;
      }
      &.on {
        left: 0;
      }
    }
  }
}
.controller {
  span, a {
    display: inline-block;
    overflow: hidden;
    font-size: 1px;
    line-height: 999px;
  }
  .pagination {
    position: absolute;
    bottom: 19px;
    left: 30px;
    span {
      float: left;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      background: map-get($color-type, gray-01);
      border-radius: 50%;
    }
    a {
      display: inline-block;
      overflow: hidden;
      margin: 0 0 0 -4px;
    }
    span.on {
      border-radius: 50%;
      background: $color-primary;
    }
  }
  .control {
    position: absolute;
    bottom: 10px;
    right: 10px;
    a {
      position: relative;
      float: left;
      width: 30px;
      height: 30px;
      background: $color-black;
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        width: 16px;
        height: 16px;
        background: svg-load('../../img/svg/icons/arrow_gray_light.svg') 50%/100% no-repeat;
        transform: translate(-50%, -50%) rotate(180deg);
        content: "";
      }
      &.next {
        margin-left: 1px;
        &::after {
          transform: translate(-50%, -50%) rotate(0);
        }
      }
    }
  }
}
.cont_area {
  margin-top:10px;
  .top_area + & {
    margin-top:-50px;
  }
  .suggest_wrap {
    margin-top: 0;
  }
  .total_wrap {
    background: none;
    .nodata {
      padding: 48px 0 47px;
      border: 1px solid map-get($color-line, line-01);
      background-color: $color-white;
      text-align: center;
      .ico_nodata {
        display: inline-block;
        width: 72px;
        height: 72px;
        background: svg-load("../../img/svg/icons/info_big.svg") no-repeat;
        background-size: 100%;
      }
      .txt_nodata {
        margin-top: 12px;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        color: $color-gray;
      }
    }
  }
}
.hr_thmblst {
  padding: 0 29px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 2px;
  background: $color-white;
  li {
    border-top: 1px dotted map-get($color-line, line-01);
    &:first-child {
      border: 0;
    }
  }
  .in {
    display: block;
    position: relative;
    padding: 30px 132px;
    min-height: 195px;
    box-sizing: border-box;
  }
  .thmb {
    position: absolute;
    top: 30px;
    left: 0;
  }
  .tit {
    display: block;
    overflow: hidden;
    padding-top: 6px;
    max-height: 50px;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -.5px;
  }
  a:hover .tit {
    text-decoration: underline;
  }
  .info {
    position: relative;
    z-index: 1000;
    margin-top: 12px;
    dt {
      display: none;
    }
    dd {
      color:$color-gray;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .btn {
    position: absolute;
    top: 40px;
    right: 0;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  /* safari only */
  ::i-block-chrome,.submain_front .top_area .submain_goods li img {border-radius:2px 0 0 2px}
}

/* etc */
#container.etc_cont .inner {padding-top:243px }
.common_container.etc_cont {background:#f8f9fa}
.etc_cont .etc_box {height:610px;padding:120px 30px 120px;border:1px solid map-get($color-line, line-01);border-radius:2px;background:$color-white}
.etc_box .con_center {width:338px;margin:0 auto}
.con_center .txt_img {margin-bottom:20px;line-height:29px;text-indent:-9999px;background:url(../img/common/sp_etc.png) no-repeat;background-position:-238px -514px}
.con_center .txt_img.txt_adult {background-position:-238px -543px}
.con_center .sub_txt {padding:26px 0;font-size:12px;color:$color-gray;line-height:20px;border-top:1px solid map-get($color-type, gray-01);}
.con_center .b_login {width:339px;height:52px;background-position:0 -537px}
.con_center .adult_acc {width:339px;height:52px;background-position:0 -590px}
.con_center .find_list {position:relative;overflow:hidden;padding-top:28px;margin:0 auto;text-align:center}
.con_center .find_list .find {color:$color-gray}
.con_center .find_list .bar {margin:0 8px;color:#e8e9ec}
.con_center .find_list a {width:101px;height:42px;margin:0 2px}
.con_center .find_list a.ok {background-position:-335px -472px}
.con_center .find_list a.cancel {background-position:-405px -280px}

.wrap_error {position:relative;height:100%;width:100%}
.etc_cont .error_box {margin:50px auto 0;font-size:0;text-align:center}
.etc_cont .error_box::before {display:block;margin:0 auto 30px;width:100px;height:100px;background:url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M90.9499 88.9995H9.05013C8.51768 89.0091 7.9923 88.8765 7.52808 88.6156C7.06386 88.3546 6.67758 87.9747 6.40899 87.5149C6.14041 87.055 5.99924 86.5319 6 85.9994C6.00077 85.4668 6.14345 84.9441 6.41335 84.485L47.3632 12.5731C47.62 12.0975 48.0006 11.7001 48.4648 11.4232C48.929 11.1462 49.4595 11 50 11C50.5405 11 51.071 11.1462 51.5352 11.4232C51.9994 11.7001 52.38 12.0975 52.6368 12.5731L93.5866 84.485C93.8566 84.9441 93.9992 85.4668 94 85.9994C94.0008 86.5319 93.8596 87.055 93.591 87.5149C93.3224 87.9747 92.9361 88.3546 92.4719 88.6156C92.0077 88.8765 91.4823 89.0091 90.9499 88.9995ZM49.1011 13.6119L8.15122 85.5238C8.06356 85.6756 8.01742 85.8479 8.01742 86.0232C8.01742 86.1985 8.06356 86.3707 8.15122 86.5226C8.24038 86.6814 8.37126 86.8127 8.52969 86.9025C8.68812 86.9923 8.86809 87.0371 9.05013 87.032H90.9499C91.1319 87.0371 91.3119 86.9923 91.4703 86.9025C91.6287 86.8127 91.7596 86.6814 91.8488 86.5226C91.9364 86.3707 91.9826 86.1985 91.9826 86.0232C91.9826 85.8479 91.9364 85.6756 91.8488 85.5238L50.8989 13.6119C50.8171 13.443 50.6894 13.3006 50.5304 13.201C50.3715 13.1013 50.1876 13.0485 50 13.0485C49.8124 13.0485 49.6285 13.1013 49.4696 13.201C49.3106 13.3006 49.1829 13.443 49.1011 13.6119Z' fill='%23A7ACB6'/%3E%3Cpath d='M51 43C51 42.4477 50.5523 42 50 42C49.4477 42 49 42.4477 49 43V65C49 65.5523 49.4477 66 50 66C50.5523 66 51 65.5523 51 65V43Z' fill='%23A7ACB6'/%3E%3Cpath d='M51 71C51 70.4477 50.5523 70 50 70C49.4477 70 49 70.4477 49 71V73C49 73.5523 49.4477 74 50 74C50.5523 74 51 73.5523 51 73V71Z' fill='%23A7ACB6'/%3E%3C/svg%3E%0A") 50%/100% no-repeat;content:""}
.etc_cont .error_box strong {font-size:28px;color:$color-black;line-height:32px;font-weight:700}
.etc_cont .error_box p {font-size:14px;color:$color-gray;line-height:26px;margin:23px 0 22px}
.etc_cont .error_box p a {color:$color-primary}
.etc_cont .error_box .error_txt {margin:0}
.etc_cont .error_box .error_txt .fw_normal {display:block;margin-top:8px;font-weight:400}
.etc_cont .error_box .error_list_area {margin-top:23px}
.etc_cont .error_box .error_list_area .error_txt {font-weight:700;line-height:24px}
.etc_cont .error_box .error_list_area .error_list {margin-top:4px} 
.etc_cont .error_box .error_list {margin-top:9px}
.etc_cont .error_box .error_item {display:flex;align-items:center;justify-content:center;font-size:14px;line-height:22px;color:map-get($color-type, gray-01);}
.etc_cont .error_box .error_item::before {width:2px;height:2px;margin-right:4px;background:map-get($color-type, gray-01);vertical-align:middle;content:""}
.etc_cont .error_box .error_list_etc {display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:10px}
.etc_cont .error_box .error_list_etc::before,.etc_cont .error_box .error_list_etc::after {content:""}
.etc_cont .error_box .error_list_etc .dot,.etc_cont .error_box .error_list_etc::before,.etc_cont .error_box .error_list_etc::after {width:2px;height:2px;margin:4px 0;background:map-get($color-type, gray-01);}
.etc_cont .error_box .error_captcha {width:344px;height:94px;margin:30px auto 0}
.etc_cont .error_box .error_caption {margin:18px 0 22px}
.etc_cont .error_box .error_caption + .btn_area {margin-top:22px}
.etc_cont .error_box .error_caption .error_txt {font-size:14px;line-height:22px}
.etc_cont .error_box .error_caption .error_item + .error_item {margin-top:4px}
.etc_cont .error_box .error_illust_img {display:block;width:216px;height:138px;margin:36px auto 0}
.etc_cont .error_box .error_illust_img+.error_define_box {margin-top:-42px}
.etc_cont .error_box .error_define_box {position:relative;width:568px;margin:36px auto 22px;padding:30px;background:$color-white;box-sizing:border-box}
.etc_cont .error_box .error_define_box .error_list_area {margin-top:0}
.etc_cont .error_box .error_define_box .error_list {margin-top:4px}
.etc_cont .error_box .error_define_box .error_item {font-size:13px}
.etc_cont .error_box .wrap_illust_box .error_illust_img {margin-top:28px}
.etc_cont .error_box .btn {width:120px;padding:9px 0;text-align:center;font-size:14px;font-weight:500;line-height:22px}
.etc_cont .error_box .btn + .btn {margin-left:6px}

.mbanner_left {position:absolute;top:342px;left:-116px;font-size:0}

/* 광고구좌 영역 */
.box_banner a {display:block}
.box_banner img {width:100%}

/* 클린예매 서비스 팝업 */
.ly_clean_reserve {position:absolute;z-index:20;width:410px;height:423px;padding:20px;border:1px solid $color-black;box-sizing:border-box;background-color:$color-white;font-weight:normal;text-align:center}
.ly_clean_reserve .sp {display:block;background-image:url(../img/common/sp_sports_sub.png);background-repeat:no-repeat;font-size:0;vertical-align:middle}
.ly_clean_reserve .pop_tit {display:block;padding-bottom:15px;border-bottom:1px solid map-get($color-line, line-01)}
.ly_clean_reserve .pop_tit .sp {width:140px;height:17px;margin:0 auto;background-position:-344px -17px}
.ly_clean_reserve .pop_contents .dsc {margin-top:10px;color:$color-gray}
.ly_clean_reserve .img_dsc {width:373px;height:256px;margin-top:20px;background-image:url(../img/common/clean_reserve_info.jpg);font-size:0}
.ly_clean_reserve .lst_dsc {margin-top:11px;padding-left:30px;text-align:left}
.ly_clean_reserve .lst_dsc li {margin-top:5px;font-size:12px;color:$color-gray;letter-spacing:-1px}
.ly_clean_reserve .lst_dsc li:first-child {margin-top:0}
.ly_clean_reserve .btn_close {position:absolute;top:0;right:0;padding:11px;border:0;background-color:transparent;cursor:pointer}
.ly_clean_reserve .btn_close .sp {width:12px;height:11px;background-position:-347px -35px}