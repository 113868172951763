.blind,
caption,
caption span,
legend {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
}
.skip_navi { 
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  color:$color-white;
  text-align: center;
  z-index: map-get($z-index, nav);
  &:focus,
  &:active {
    width: 100%;
    height: auto;
    padding: 5px 0;
    background: #000;
  }
}

.swiper-pagination-bullet-active {
  background: #fa2828 !important;
}

/* web/css/common.css */
.tbl04 th span,
.sport_ban li .today,
.ui-datepicker .ui-datepicker-header a,
.layer a.close, 
.detail_box_top .title em, 
.wing_ban strong, 
.list_search .input_field .btn_search, 
.notes2 ol li a span, 
.mberguide dd p span, 
.btn_help_gray, 
.slide_ctrl button {display:inline-block;overflow:hidden;font-size:0;background:url(../img/common/sp.png) no-repeat}

/* dot line */
.dotline_x {background:url(../img/common/bg_dotline.png) repeat-x 1px 100%}
.dotline_y {position:relative}
.dotline_y::after {position:absolute;right:0;top:1px;height:calc(100% - 2px);border-right:1px dotted #ecedf2;content:""}

/* input style */
input[type=text].input,
input[type=password].input {border:1px solid #e8eaf1;padding:1px 10px;height:28px;line-height:100%;line-height:28px;vertical-align:middle;box-sizing: border-box}
input[type=text].input::placeholder,
input[type=password].input::placeholder {color:#a7acb6}
input[type=text].input:disabled {background-color:#f5f6f9}
.inp_tx {display:inline-block;position:relative;border:1px solid #ecedf2}
.inp_tx label,
.inp_tx .inp_lb {position:absolute;top:6px;left:10px;font-size:12px;color:#a7acb6;letter-spacing:-.5px;line-height:15px}
.inp_tx input {width:100%;height:26px;padding:0 10px;border:0;line-height:100%;box-sizing:border-box}
.radio.no_label {margin:-1px 0 0}

/* 스크롤박스 */
.scrl_box {border:1px solid #e6e6e6;background:$color-white;overflow-y:scroll;padding:15px 15px 15px 20px;line-height:20px;height:80px;color:#62676c}

/* calendar */
.ui-datepicker .ui-datepicker-header {position:relative;margin-bottom:8px;text-align:center;height:15px;vertical-align:top}
.ui-datepicker .ui-datepicker-header a {overflow:hidden;position:absolute;top:-1px;width:9px;height:13px;vertical-align:middle;line-height:-999px;cursor:pointer}
.ui-datepicker .ui-datepicker-header a.ui-datepicker-prev {left:64px;transform:rotate(180deg)}
.ui-datepicker .ui-datepicker-header a.ui-datepicker-next {right:64px}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title span {@include font-family('Roboto');font-weight:700;font-size:16px;line-height:15px;vertical-align:top}
.ui-datepicker-body table {width:100%;font-size:13px}
.ui-datepicker-body table th {font-weight:400;color:#62676c;height:30px}
.ui-datepicker-body table td {@include font-family('Roboto');text-align:center;color:#ccc;height:30px}
.ui-datepicker-body table td:first-child a {color:#dd6045}
.ui-datepicker-body table td:last-child a {color:#3d85b9}
.ui-datepicker-body table td.selected a {color:$color-white;background:$color-black}
.ui-datepicker-body table td a {display:block;height:30px;color:$color-black;font-weight:500;line-height:30px}

/* select box*/
.selectbox {display:inline-block;position:relative;vertical-align:top}
.selectbox .select {@include ellipsis;position:relative;padding:4px 34px 4px 10px;width:100%;box-sizing:border-box;color:$color-black;font-size:14px;line-height:22px;background:$color-white}
.selectbox .select::before {position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid map-get($color-type, gray-03);box-sizing:border-box;content:""}
.selectbox .select::after {position:absolute;top:50%;right:13px;margin-top:-2px;border-style:solid;border-color:currentColor transparent transparent;border-width:4.5px 3.5px;content:""}
.selectbox .select.disabled {color:map-get($color-type, gray-02)}
.selectbox .select_list {display:none;position:absolute;left:0;z-index:map-get($z-index, nav);width:100%;max-height:211px;@include scrollbar;border:1px solid $color-black;border-top:0;box-sizing:border-box;background:$color-white}
.selectbox .select_list li:hover {background:#f8f9fa}
.selectbox .select_list li a {@include ellipsis;padding:7px 9px;width:100%;height:26px;box-sizing:border-box;color:$color-black;font-size:12px;text-align:left;background:$color-white}
.selectbox .select_list li a:hover,
.selectbox .select_list li a[aria-selected="true"] {font-weight:500;background:map-get($color-background, bg-02)}
.selectbox.is-active .select::before {border-color:$color-black}
.selectbox.is-active .select::after {margin-top:-6px;border-top-color:transparent;border-bottom-color:currentColor}
.selectbox.is-active .select_list {display:block}
.selectbox_v2 .select {background:url(../img/common/bg_select31.gif) no-repeat 100% 0}
.selectbox_v2 {height:31px}
.selectbox_v2 a {height:31px;line-height:31px}
.selectbox_v3 .select {background-position:98% -31px}
.selectbox_v4 {height:36px;background-color:#fff}
.selectbox_v4 .select {background-position:100% -90px;line-height:36px}

/* button */
.btn {display:inline-flex;overflow:hidden;align-items:center;justify-content:center;padding:9px 11px;border-radius:6px;box-sizing:border-box;font-weight:500;font-size:14px;line-height:18px;vertical-align:top;-webkit-tap-highlight-color:rgba(0, 0, 0, 0)}
.btn_full {background:$color-black;border:1px solid $color-black;color:#fff}
.btn_full:hover {border-color:#000;color:#fff;background:#000}
.btn_full_point {border:1px solid $color-primary;background:$color-primary;color:#fff}
.btn_full_point:hover {border-color:#c82020;color:#fff;background:#c82020}
.btn_full_point2 {border:1px solid #ff8700;background:#ff8700;color:#fff}
.btn_full_point2:hover {border-color:#d47000;color:#fff;background:#d47000}
.btn_full:disabled,
.btn_full[aria-disabled=true],
.btn_full.disabled,
.btn_full_point.disabled,
.btn_full_point.is_disabled,
.btn_full_point2.is_disabled {border-color:map-get($color-background, bg-04);color:map-get($color-type, gray-02);background-color:map-get($color-background, bg-04);cursor:default}
.btn_blank {border:1px solid map-get($color-line, line-04);background:$color-white;color:$color-black}
.btn_blank:not(.disabled):hover {border-color:$color-black;background:#f5f6f9}
.btn_blank:disabled,
.btn_blank[aria-disabled=true],
.btn_blank.disabled {border-color:map-get($color-line, line-04);color:#a7acb6;background-color:$color-white}
.btn_blank_point {border:1px solid $color-primary;background:$color-white;color:#fa2828}
.btn_blank_point:not(.disabled):hover {background:#f5f6f9}
.btn_blank_point.disabled {border-color:#dcdde1;color:#a7acb6;cursor:default}
.btn_text {display:inline-block;border:1px solid #dcdde1;border-radius:6px;line-height:18px;color:#62676c;padding:4px 6px;font-size:13px;box-sizing:border-box}
.btn_text:hover {border-color:$color-black;background:#f5f6f9;text-decoration:none !important}
.btn_text2 {color:$color-black;font-size: 14px;line-height: 22px;text-decoration: underline;display: inline-block;margin-top: 7px}
.btn_text3 {display:inline-block;color:$color-black;font-size:12px;letter-spacing:-1px;background:url(../img/common/sp_ico.gif) no-repeat 100% -61px;padding:0 8px;vertical-align:top}
.btn_text4 {display:inline-block;height:22px;margin-left:10px;padding:0 6px;border:1px solid #d4d4d4;border-radius:1px;line-height:24px;font-size:12px;color:#62676c;letter-spacing:-1px}
.btn_bor_red {display:inline-block;border:1px solid $color-primary;background:$color-white;font-size:12px;color:$color-primary;cursor:pointer}
.btn_red {display:inline-block;background:$color-primary;font-size:12px;color:$color-white;cursor:pointer}
.btn.btn_registration{width:122px;height:42px;background: #242428;color:#fff;border-radius: 6px;text-align: center;line-height: 42px;font-size: 14px;font-weight: 500}
.btn.btn_reserve{width:110px;padding:9px 11px;border:1px solid #fa2828;border-radius:6px;background:#fa2828;font-size:14px;font-weight:500;color:#fff;line-height:22px;text-align:center;box-sizing:border-box}
.btn.btn_reserve:hover{border-color:#c82020;background:#c82020}
.btn.btn_reserve_scdl{width:110px;padding:9px 11px;border:1px solid #e4e6e8;border-radius:6px;background:#e4e6e8;font-size:14px;font-weight:500;color:#242428;line-height:22px;text-align:center;box-sizing:border-box;cursor:default}
.btn.btn_reserve_end{width:110px;padding:9px 11px;border:1px solid #e4e6e8;border-radius:6px;background:#e4e6e8;font-size:14px;font-weight:500;color:#a7acb6;line-height:22px;text-align:center;box-sizing:border-box;cursor:default}

/* paging */
.paging {text-align:center;margin-top:30px}
.paging a,
.paging strong {display:inline-block;position:relative;min-width:20px;margin-right:2px;width:34px;line-height: 34px;font-weight:500;font-size:14px;color:#878d95;text-decoration:none;vertical-align:top;font-family:'Roboto',-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,Arial,sans-serif;height:34px}
.paging strong {border-color:$color-black;color:$color-white;background: $color-black;border-radius: 50%}
.paging a.first,
.paging a.prev,
.paging a.next,
.paging a.end {width:24px;height:34px;padding:0;border:0;font-size: 0;vertical-align: middle;position: relative;margin: 0 4px}
.paging a.first::after,.paging a.prev::after,.paging a.next::after,.paging a.end::after {display: block;content: '';position: absolute;width: auto;height: auto;top: 50%;transform: translate(-50%,-50%);left: 50%}
.paging a.prev::after,.paging a.first::after {transform:translate(-50%,-50%) rotate(180deg)}

/* paginate */
.paginate {margin:0;padding:30px 0 60px;border-top:1px solid #ecedf2;font-size:0;text-align:center}
.paginate .page_num_area {display:inline-block;margin:0 10px;height:34px;line-height:34px;font-size:0}
.paginate .page_num {display:inline-block;position:relative;min-width:20px;margin-right:2px;width:34px;line-height: 34px;font-weight:500;font-size:14px;color:#878d95;text-decoration:none;vertical-align:top;font-family:'Roboto',-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,Arial,sans-serif;height:34px}
.paginate .page_num:first-child {margin-left:0}
.paginate strong.page_num {border-color:$color-black;color:$color-white;background: $color-black;border-radius: 50%}
.paginate .page_arrow {width:24px;height:34px;line-height:34px;padding:0;border:0;font-size:0;vertical-align:middle;position:relative;margin:0 4px;display:inline-block}
.paginate .page_arrow::after {display:block;content:"";position:absolute;width:auto;height:auto;top:50%;transform:translate(-50%,-50%);left:50%}
.paginate .page_arrow:first-child {margin-left:0}
.paginate .pre::after,.paginate .pre_more::after {transform:translate(-50%,-50%) rotate(180deg)}

/* sns */
.social {position:absolute;top:30px;right:30px;margin-top:21px;border:1px solid #dcdde1;border-radius:2px;background:$color-white;font-size:0;line-height:30px;color:$color-black}
.social:after {display:block;clear:both;content:""}
.social li {display:inline-block}
.social li + li {border-left: 1px solid #dcdde1}
.social li a {display:block;width:30px;height:30px;text-align:center}
.social li a::before {display:inline-block;width:18px;height:18px;vertical-align:middle;content:""}
.social li.url a span {font-size:11px;font-weight:500;text-decoration:underline}
.social li.facebook a::before {background-image: svg-load('../../img/svg/icons/facebook.svg')}
.social li.twitter a::before {background-image: svg-load('../../img/svg/icons/twitter.svg')}
.social li.url a::before {content:none}

/* dimmed */
.loading_layer {display:none;position:fixed;top:0;left:0;z-index:1000;width:100%;height:100%}
.dimmed {display:none;position:fixed;top:0;left:0;z-index:200;width:100%;height:100%;background:#000;opacity:.5;filter:alpha(opacity=50);-ms-filter:'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)'}
.dimmed2 {display:none;position:absolute;top:0;left:0;z-index:5;width:100%;height:100%;background:$color-black;opacity:.7;filter:alpha(opacity=70);-ms-filter:'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)'}
.loading_layer>.dimmed {display:block}

/* table */
.tbl table {width:100%}
.tbl table th,
.tbl table td {line-height:18px;padding:8px 0 6px}
.tbl_add_info .nodata {padding:20px 0;font-size:13px;color:#62676c;line-height:13px;text-align:center}
.tbl_add_info .elp {color:#62676c}
.tbl_add_info .item {margin-right:4px}
.basic_tbl {border-bottom:1px solid #ecedf2}
.basic_tbl table {width:100%}
.basic_tbl table th {color:#62676c;font-size:14px;line-height:20px;letter-spacing:-1px}
.basic_tbl table td {padding:8px 9px;color:#62676c;font-size:14px;line-height:22px;word-break:break-word}
.basic_tbl table thead th {padding:17px 16px 14px;border-top:1px solid #cfd0d7;background:#f8f9fa}
.basic_tbl table tbody td {border-top:1px solid #ecedf2;text-align:center}
.basic_tbl table tbody tr:first-child td {border-top:0}
.basic_tbl table tbody td.p_reative {color:$color-black}
.basic_tbl table tbody td a {color:$color-black}
.basic_tbl table tbody td a:hover {text-decoration:underline}
.basic_tbl table tbody .disabled td, 
.basic_tbl table tbody .disabled td a {color:#878d95}
.basic_tbl table thead .radio, .basic_tbl table thead .checkbox {margin-top:-4px;margin-bottom:-1px}
.basic_tbl table tbody .radio, .basic_tbl table tbody .checkbox {margin-top:-5px}
.basic_tbl2 .line th {border-top:1px solid #cfd0d7 !important}
.basic_tbl2 .line tr:first-child th, 
.basic_tbl2 .line tr:first-child td {border-top:0}
.basic_tbl_v2 table tbody td {border-right:1px solid #ececec !important}
.basic_tbl_v2 table tbody td.end {border-right:0 !important}
.basic_tbl_v3 table thead th {padding:13px 0 12px}
.basic_tbl_v3 table tbody td {padding:9px 5px 6px;text-align:center}
.basic_tbl_v3 .btn_blank {display:block;width:100%;padding:4px 11px;font-size:13px;font-weight:400;line-height:18px;text-decoration:none;box-sizing:border-box}
.basic_tbl_v3 .btn_blank:hover {text-decoration:none}
.basic_tbl_v4 table {width:100%;border-bottom:1px solid #ecedf2;border-top: 1px solid #cfd0d7}
.basic_tbl_v4 table tr:first-of-type td,
.basic_tbl_v4 table tr:first-of-type th {border-top:1px solid #cfd0d7}
.basic_tbl_v4 table tfoot tr:first-of-type td,
.basic_tbl_v4 table tfoot tr:first-of-type th {border-top: 1px solid #ecedf2}
.basic_tbl_v4 table tbody th, 
.basic_tbl_v4 table tbody td,
.basic_tbl_v4 table tfoot th {padding:7px 18px;border-top:1px solid #ecedf2;color:$color-black;line-height:22px;text-align:left;word-break:break-word;font-size: 14px}
.basic_tbl_v4 table tbody th {color:#62676c}
.basic_tbl_v4 table tbody .line th,
.basic_tbl_v4 table tbody .line td {border-top:1px solid #ecedf2}
.basic_tbl_v4 .final th,
.basic_tbl_v4 .final td {border-top:1px solid #ecedf2;background:#fff}
.basic_tbl_v4 table .th {border-right:1px solid #ececec}
.basic_tbl_v4 table tbody th,
.basic_tbl_v4 table tfoot th {background:#f8f9fa;font-weight:400;color:#62676c}
.basic_tbl_v4 table tbody .tr,
.basic_tbl_v4 table tfoot .tr {padding-left:0;padding-right:20px}
.basic_tbl_v4 table tbody .bg_f {background:#fff !important}
.basic_tbl_v4 table tbody .btn_blank {margin-left:2px;padding:4px 11px;font-size:13px;font-weight:400;line-height:18px;color:$color-black;vertical-align:baseline}
.basic_tbl_v4 table tbody td .dsc {margin-top:3px;font-size:13px}
.basic_tbl.basic_tbl_v4 table {border-bottom:0}
.basic_tbl.basic_tbl_v4.thbold_ver th {font-weight:bold;text-align:center;color:$color-black}
.basic_tbl_v4 tfoot td {padding:9px 8px 8px 20px;font-size:14px;font-weight:bold;text-align:right;padding-right:20px}
.basic_tbl_v4 .final_option .color_point {font-size:14px}
.basic_tbl_v4 .final_option .final_list {font-size: 14px;line-height: 17px;font-weight: 400}
.basic_tbl_v4.tbl_add_info .nodata {padding:20px 0;font-size:13px;color:#62676c;line-height:13px;text-align:center}
.basic_tbl_v5 {border-top:1px solid #cfd0d7}
.basic_tbl_v5 table th {font-weight:normal;color:$color-black;border-top:1px solid #ececec;padding:12px 20px 10px;text-align:left;position:relative}
.basic_tbl_v5 table tr:first-child th {border-top:0}
.basic_tbl_v5 table tbody td {font-size:12px;letter-spacing:-1px;color:#878d95;border-top:1px solid #ececec;border-left:1px solid #ececec;text-align:left;line-height:16px;padding:12px 20px 10px;position:relative}
.basic_tbl_v6 {border-top:1px solid #cfd0d7}
.basic_tbl_v6 table tbody th {background:#f8f9fa;text-align:left;padding-left:20px;border-top:1px solid #ecedf2;font-size: 14px}
.basic_tbl_v6 table tbody td {text-align:left;border-top:1px solid #ececec;color:$color-black;padding:9px 15px 6px}
.basic_tbl_v6 table tbody tr:first-child th {border-top:0}
.basic_tbl_v6 table tbody tr:first-child td {padding-top:9px}
.basic_tbl_v7 table tbody td {padding:9px 10px 6px 10px;vertical-align:middle}
.basic_tbl_v7 table tbody td:first-child {padding-left:20px}
.basic_tbl_v7 table .btn {margin-top:-2px}

.guide_txt + .mtmwrite_tbl {margin-top:27px}
.mtmwrite_tbl {margin-top:36px;border-width:1px 0;border-style:solid;border-color:#cfd0d7;border-bottom-color:#ecedf2}
.mtmwrite_tbl table {width:100%}
.mtmwrite_tbl th,.mtmwrite_tbl td {background:url(../img/common/bg_dotline_d8.png) repeat-x -2px -2px;padding:31px 0 30px 20px;line-height:18px;font-size: 14px}
.mtmwrite_tbl tr:first-child th,.mtmwrite_tbl tr:first-child td {background:none}
.mtmwrite_tbl th {text-align:left;letter-spacing:-1px}
.mtmwrite_tbl .user_id {color:$color-primary;font-size:14px;font-weight:700}
.mtmwrite_tbl .notes {background:none;font-size:12px;color:#62676c}
.mtmwrite_tbl .category {overflow:hidden;margin-bottom:-18px}
.mtmwrite_tbl .category dt {float:left;width:65px;font-weight:bold}
.mtmwrite_tbl .category dd {padding-bottom:18px}
.mtmwrite_tbl .attach {background:#f8f9fa;padding:6px 20px;font-size:12px;color:#62676c}
.mtmwrite_tbl .attach .btn_text {background:$color-white;height:28px;line-height:28px;text-align: center;width: 90px;margin-right:5px;color:$color-black;border-radius: 6px;border-color:#dcdde1;color:$color-black}
.mtmwrite_tbl .attach_list {border:1px solid #e8eaf1;padding:16px 21px;height:96px;margin-top:10px}
.mtmwrite_tbl .attach_list li {height:23px;line-height:23px}
.mtmwrite_tbl .attach_list li span {margin-right:5px;vertical-align:top}
.mtmwrite_tbl .attach_list li a.delete {display: inline-block;vertical-align:middle;margin-top:-2px;margin-left:7px;text-indent: -9999px}
.mtmwrite_tbl .attach_list_view {border:none;padding:0;color:#62676c;margin:0;height:auto}
.mtmwrite_tbl .attach_list_view li {height:20px;line-height:20px}
.mtmwrite_tbl tr.border_solid th,.mtmwrite_tbl tr.border_solid td {background:none;border-top:1px solid #ececec;color:$color-black}
.mtmwrite_tbl span.bar {color:$color-black;font-size: 14px;line-height: 18px}
.mtmwrite_tbl .td_title {font-weight:bold;font-size:16px;color:$color-black;letter-spacing:-1px}
.tblbx {display:table;width:100%;table-layout:fixed}

/* 테이블 내 레이어 */
.layer_mother {display:inline-block;position:relative;width:16px;height:16px;margin:-1px 0 1px 1px;vertical-align:middle}
.layer_mother .help {display:block;text-indent: -9999px;font-size: 0;margin-left:2px}
.layer_mother a.help:hover+.layer {display:block;z-index:100}
.layer_mother .layer {display:block;top:20px;left:5px;display:none}

/* layer */
.layer {display:none;position:absolute;z-index:10;border:1px solid $color-black;background:$color-white;border-radius:2px;padding:0 20px 15px}
.layer a.close {position:absolute;top:14px;right:20px}
.layer strong {display:block !important;padding:9px 0;font-size:14px;letter-spacing:-1px;line-height:22px;border-bottom:1px solid #ecedf2;margin-bottom:6px;color:$color-black}
.layer table {font-size:13px;color:#62676c}
.layer table th {vertical-align:top;padding:1px;font-weight:normal;text-align:left;letter-spacing:-1px}
.layer table th img {margin-top:-1px;margin-top:2px}
.layer table td {padding:1px;line-height:16px}
.layer table td span.num {font-weight:normal}
.layer .notice {background:#f5f6f9;margin:15px -20px -15px;padding:10px 20px 7px}
.layer .notice p {padding-left:10px;font-size:13px;letter-spacing:-1px;color:#62676c;letter-spacing:-1px;line-height:16px;text-indent:-10px}
.layer .notice .notice_list li {text-indent:-7px;padding:2px 0 3px 7px}
.layer .layer_text {font-size:13px;letter-spacing:-1px;color:#62676c;line-height:18px;font-weight:normal}
.layer .layer_text>li {list-style:disc inside}
.layer .layer_text ul {margin-left:10px}
.layer .layer_text dt {float:left;margin-right:10px;color:$color-black}
.layer .layer_text dd {text-align:left;margin-left:60px}
.layer.only_txt {padding:10px 10px 8px}
.layer.type2 {z-index:300;width:498px;padding:0}
.layer.type2 .header {position:relative;height:60px;background:#ff1414}
.layer.type2 h1 {width:118px;height:18px;margin:21px 0 0 30px;background:url(../img/common/sp_pop.png) no-repeat;background-position:-233px -18px}
.layer.type2 h2 {font-size:16px;color:$color-black;letter-spacing:-1px}
.layer.type2 .container {padding:39px 29px 50px}
.layer.type2 .content {border:1px solid #cfd0d7;border-width:1px 0}
.layer.type2 .tit {height:74px;padding:0 10px;border-bottom:1px solid #ececec;line-height:77px}
.layer.type2 .tx {padding:21px 9px 41px;color:$color-black;line-height:22px}
.layer.type2 .btn_area {margin-top:30px;text-align:center}
.layer.type2 .btn {display:inline-block;position:static;width:101px;height:42px}
.layer.type2 .btn.ok {background-position:-307px -390px}
.layer.type2 .close {top:50%;right:29px;margin-top:-6px;background-position:-208px -32px}
.layer.type2 .check_area {overflow:hidden;padding:8px 20px 6px;background:#f4f4f4}
.layer.type2 .reserve_agree {float:right}
.layer.type2 .reserve_agree label {font-size:12px;color:#62676c;letter-spacing:-1px}
.layer.type3 {width:598px}
.layer.type3 .layer_text>li {list-style:none}
.layer.notice h1 {width:121px;background-position:-368px 0}
.layer.notice .tx {overflow-y:auto;height:200px}
.layer.notice_type2 .header {background:#717070}
.layer.notice_type2 h1 {width:121px;background-position:-368px 0}

/* 좌석 위치보기 툴팁 */
.seat_bx {position:relative}
.seat_bx .btn_seat {display:block;padding-right:10px;font-size:14px;color:#fa2828;text-decoration:underline;cursor:pointer;background:none}
.seat_bx .tooltip {position:absolute;top:-25px;left:26px;width:max-content;height:23px;border:1px solid #242428;font-size:14px;line-height:23px;letter-spacing:-1px;text-align:center;background:#fff}
.seat_bx .tooltip::before,
.seat_bx .tooltip::after {position:absolute;width:0;height:0;border-style:solid;content:""}
.seat_bx .tooltip::before {left:-1px;bottom:-7.5px;border-color:#242428 transparent;border-width:7px 7px 0 0}
.seat_bx .tooltip::after {left:0;bottom:-5px;border-color:#fff transparent;border-width:5.5px 5.5px 0 0}
.seat_bx .tooltip .btn_seat {text-decoration:none}
.seat_bx .tooltip .text {display:block;padding-left:5px;width:calc(100% - 5px);height:100%;color:#242428;font-size:14px;line-height:23px;text-align:left}
.seat_bx .tooltip .btn_close {position:absolute;top:0;right:0;width:15px;height:23px}
.seat_bx .tooltip .btn_close::before,
.seat_bx .tooltip .btn_close::after {overflow:hidden;position:absolute;top:11px;right:5px;width:9px;height:1px;border-radius:1px;background-color:#242428;transform:rotate(45deg);content:""}
.seat_bx .tooltip .btn_close::after {transform:rotate(-45deg)}

/* 메인 팝업 */
.layer_main_pop_wrap {position:absolute;top:188px;left:30px;z-index:500;width:312px;min-height:100px;border:1px solid #cccccc;background-color:$color-white;box-sizing:border-box}
.layer_main_pop_wrap .main_pop_list {padding:5px;font-size:0;box-sizing:border-box}
.main_pop_list .main_pop_item {display:inline-block;width:50%;padding:5px 4px;box-sizing:border-box}
.main_pop_item .link {display:block;overflow:hidden;position:relative;width:100%;height:172px;border:1px solid #eeeeee;background-image:url(../img/common/bg_main_pop_3.png);background-repeat:no-repeat;background-size:cover;box-sizing:border-box}
.main_pop_item .img {display:block;width:100%;height:100%}
.main_pop_item .flag {position:absolute;top:0;left:0;padding:4px 5px;width:32px;background-color:#1563a1;font-size:11px;color:$color-white;line-height:12px;text-align:center;white-space:pre-line;word-break:break-all;box-sizing:border-box}
.main_pop_item .flag.single {background:#f10000}
.main_pop_item .flag.seat {background:#ff6100}
.main_pop_item .desc_bx {position:absolute;bottom:0;left:0;width:100%;height:40px;padding:4px;background-color:rgba(0,0,0,0.7);text-align:center;box-sizing:border-box}
.main_pop_item .desc_bx .desc {position:absolute;top:50%;left:0;width:100%;transform:translateY(-50%);font-size:12px;line-height:16px;color:$color-white}
.main_pop_item .desc_bx .desc_txt {display:inline-block;vertical-align:middle}
.main_pop_item .desc_bx .em_desc {display:inline-block;font-weight:bold;vertical-align:middle}
.main_pop_list.single .main_pop_item {width:100%}
.main_pop_list.single .main_pop_item .link {height:350px}
.main_pop_list.banners {padding:8px 4px}
.main_pop_list.banners .main_pop_item {width:100%;padding:2px 5px}
.main_pop_list.banners .main_pop_item .link {height:102px;background-size:34%}

.layer_main_pop_wrap .main_pop_footer {position:relative;height:33px;padding:0 32px 0 10px;background:$color-white;border-top:1px solid #cccccc;font-size:0px;box-sizing:border-box}
.layer_main_pop_wrap .main_pop_footer:before {display:inline-block;height:100%;vertical-align:middle;content:""}
.layer_main_pop_wrap .main_pop_footer .check_area {display:inline-block;font-size:0;vertical-align:middle}
.layer_main_pop_wrap .main_pop_footer .check_area .ipt_chk {overflow:hidden;position:absolute;width:1px;height:1px;margin:-1px;padding:0;border:0;clip:rect(0,0,0,0);-webkit-appearance:none;appearance:none}
.layer_main_pop_wrap .main_pop_footer .check_area .txt {display:inline-block;font-size:13px;color:#62676c;letter-spacing:-0.5px;vertical-align:middle}
.layer_main_pop_wrap .main_pop_footer .check_area .ipt_chk + .label:before {display:inline-block;width:16px;height:16px;margin-right:7px;background:url(../img/common/sp.png) no-repeat;background-size:424px auto;background-position:-283px -32px;vertical-align:middle;content:""}
.layer_main_pop_wrap .main_pop_footer .check_area .ipt_chk:checked + .label:before {background-position:-299px -32px}
.layer_main_pop_wrap .main_pop_footer .btn_close {position:absolute;top:0;right:0;width:32px;height:32px;background-color:$color-white}
.layer_main_pop_wrap .main_pop_footer .btn_close .sp {display:block;overflow:hidden;width:12px;height:11px;margin:0 auto;background-image:url(../img/common/sp.png);background-repeat:no-repeat;background-size:424px auto;background-position:-222px -32px;font-size:1px;line-height:999px}

/* 메인 합업 하단 버튼*/
.layer_main_pop_wrap .main_pop_footer .btn_today + .btn_close {position:relative}
.layer_main_pop_wrap .main_pop_footer .btn_today + .btn_close::before {position:absolute;top:50%;width:1px;height:80px;background:#ecedf2;transform:translateY(-50%);content:""}

/* 메인 플래시 배너 팝업 */
.full_page_pop {position:fixed;left:0;top:0;right:0;bottom:0;z-index:1000;width:100%;height:100%}
.full_page_pop .dimmed {display:block;z-index:1000;opacity:0.8}
.layer_flash_pop_wrap {position:fixed;top:50%;left:50%;z-index:1000;transform:translateX(-50%) translateY(-50%)}
.layer_flash_pop_wrap .flash_pop_item {width:600px}
.layer_flash_pop_wrap .flash_pop_item .link {display:block}
.layer_flash_pop_wrap .flash_pop_item .link .img {max-width:600px}
.layer_flash_pop_wrap .flash_pop_footer {position:absolute;left:0;bottom:0;width:100%}
.layer_flash_pop_wrap .flash_pop_footer .time_noti {color:#b1b1b1;font-size:19px;text-align:center;line-height:23px}
.layer_flash_pop_wrap .pop_btn_close {position:absolute;right:0;top:0;width:60px;height:60px;border:1px solid $color-white;border-radius:50%;background-color:#000;opacity:0.5}
.layer_flash_pop_wrap .pop_btn_close::before {position:absolute;left:15px;top:28px;width:28px;height:2px;background-color:$color-white;transform:rotate(-45deg);content:""}
.layer_flash_pop_wrap .pop_btn_close::after {position:absolute;left:15px;top:28px;width:28px;height:2px;background-color:$color-white;transform:rotate(45deg);content:""}


/* 게시글 없을 시 */
.helpmain_list .no_data {height:180px !important;line-height:180px !important}
table .no_data td {height:365px;vertical-align:middle !important;color:$color-black}

.ad_area {display:block;height:100px}
.ad_area.v2 {display:block;height:200px}

/* 레이어팝업 common */
.ly_pop_wrap {position:absolute;top:0;left:0;z-index:100} /* 팝업창 안의 레이어팝업용도 */
.ly_pop_wrap2 {position:fixed;top:0;left:0;right:0;bottom:0;z-index:1000;font-size:0;text-align:center} /* 기본 팝업 구조 */
.ly_pop_wrap2:before {display:inline-block;height:100%;vertical-align:middle;content:""}
.ly_pop_wrap2 .dimmed {display:block;position:fixed;top:0;left:0;right:0;bottom:0;background:$color-black;background-color:rgba(0,0,0,.8);opacity:0.8;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"}
.ly_pop_wrap2::before {display:inline-block;content:""}
.ly_pop_wrap2 .pop_contents {display:inline-block;position:relative;z-index:1000;padding:60px 37px;border-radius:5px;vertical-align:middle;background-color:$color-white;text-align:left}
.ly_pop_wrap2 .pop_contents .btn_close {position:absolute;top:0;right:0;padding:20px 19px;background-color:transparent;cursor:pointer}
.ly_pop_wrap2 .pop_contents .btn_close::before {display:block;content:""}
ㄴ
/* 딤드(dimmed)있는 레이어 팝업 */
.ly_dim_wrap {position:fixed;top:0;right:0;bottom:0;left:0;z-index:999;background:$color-black;background-color:rgba(0,0,0,.5)}
.ly_dim_wrap .layer {display:block;top:50%;left:50%;z-index:1000;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}
.ly_dim_wrap .ly_header {position:relative;box-sizing:border-box}
.ly_dim_wrap .ly_content {position:relative;box-sizing:border-box}
.ly_dim_wrap .btn_close {position:absolute;box-sizing:border-box}
.ly_dim_wrap .btn_close::before {display:inline-block;content:""}

/* WEB Table 공통 UIO (190111~ 이후 점진적 적용 예정) */
.tbl_type {border-top:1px solid #cfd0d7;border-left:0;border-right:0;border-bottom:1px solid #ecedf2}
.tbl_type .checkbox {margin:-2px 0 0 0}
.tbl_type table {width:100%}
.tbl_type tbody th {padding:10px 0 7px 19px;border-top:1px solid #ecedf2;background-color:#f8f9fa;font-weight:normal;color:#62676c;text-align:left;line-height:18px;letter-spacing:-.5px;vertical-align:middle}
.tbl_type td {padding:10px 19px 7px 19px;border-top:1px solid #ecedf2;color:$color-black;line-height:18px;letter-spacing:-.5px;vertical-align:middle}
.tbl_type tr:first-child th,.tbl_type tr:first-child td {border-top:0}
.tbl_type thead th {padding:13px 0 11px;border-bottom:1px solid #ecedf2;background-color:#f8f9fa;font-weight:normal;line-height:18px}
.tbl_type tfoot tr:first-child th {padding:10px 0 7px 19px;line-height:18px;text-align:left}
.tbl_type tfoot tr:first-child td {text-align:right}
.tbl_type tfoot tr:first-child th, .tbl_type tfoot tr:first-child td {border-top: 1px solid #ecedf2}
.tbl_type.thead_add td {padding-left:0;text-align:center}