body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
table,
th,
td,
form,
fieldset,
legend,
textarea,
input,
select,
textarea,
a,
button,
article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
  margin: 0;
  padding: 0;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
  display: block
}
html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none
}
img,
form,
fieldset,
svg,
li {
  border: 0;
  vertical-align: top
}
ul,
ol {
  list-style: none
}
button {
  overflow: visible;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
button:disabled {
  cursor: default;
}
button::-moz-focus-inner {
  padding: 0;
  margin: -1px;
}
input,
button,
select {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
address,
caption,
em {
  font-style: normal
}
a {
  color: #242428;
  text-decoration: none;
}
a:focus,
a:active,
a:hover {
    text-decoration: none;
}
a:hover {
  cursor: pointer;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
hr {
  display: none;
}
body,
input,
select,
textarea,
button,
pre {
  font-weight: 400;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, Apple SD Gothic Neo, 'Malgun Gothic','Pretendard JP', Arial, sans-serif;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: $color-black;
}
#app {
  display: block !important;
}