/* mypage, service center 공통 */ 
.quick_menu_wrap {
  display: flex;
  align-items: center;
  height: 135px;
  margin-bottom: 10px;
  border: 1px solid $color-black;
  border-radius: 2px;
  background: $color-white;
  box-sizing: border-box;
  &:after {
    display: block;
    clear: both;
    content: "";
  }
  .title {
    display: flex;
    flex: 0 0 auto;
    width: 257px;
    height: 100%;
    background: $color-black;
    &_txt {
        margin: auto;
        padding: 0 10px;
        color: $color-white;
        font-weight: 700;
        font-size: 26px;
        line-height: 38px;
        letter-spacing: -1px;
        word-break: break-all;
    }
  }
  .quick_menu_list {
    display: flex;
    margin: 0 auto;
    max-width: 860px;
    box-sizing: border-box;
  }
  .help_menu_guide {
    padding: 30px;
    color: #000;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-line;
  }
}
.help_cont {
  display: flex;
  position: relative;
  border: 1px solid #ecedf2;
  width: 1120px;
  border-radius: 2px;
  box-sizing: border-box;
  background: $color-white;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 256px;
    width: 1px;
    background: #ecedf2;
    content: "";
  }
  &:after{
    display: block;
    clear: both;
    content: "";
  }
}
.help_lmenu {
  flex: 0 0 255px;
  padding-top: 15px;
  width: 255px;
  &>li {
    position: relative;
    padding: 20px 18px 20px 26px;
    border-bottom: 1px solid #ecedf2;
    box-sizing: border-box;
    &>a {
      display: block;
      position: relative;
      color: $color-black;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .help_submenu_tit {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .help_submenu {
    li {
      margin-top: 8px;
      a {
        display: block;
        position: relative;
        color: #878d95;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  li.on {
    a {
      color: $color-primary;
      &:after{
        position: absolute;
        top: 50%;
        right: 0;
        width: 16px;
        height: 16px;
        background: svg-load('../../img/svg/icons/arrow_red.svg') no-repeat;
        transform: translateY(-50%);
        content: "";
      }
    }
  }
}
.help_rcont {
  flex: 1;
  width: 862px;
  padding: 40px 60px 60px;
  box-sizing: border-box;
  .h3_line {
    position: relative;
    border-bottom: 2px solid #cfd0d7;
  }
  .rcon_head {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
  }
  .guide_txt {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.5px;
    line-height: 20px;
    color: #878d95;
    margin: 27px 0 30px;
    a {
      color: $color-primary;
      text-decoration: underline;
    }
    &.v2 {
      font-size: 12px;
      text-align: right;
      margin: 0 0 10px;
    }
  }
  &:after{
    display: block;
    clear: both;
    content: "";
  }
}

.notes {
  margin-top: 50px;
  padding: 12px 22px;
  background:#f8f9fa;
  p span {
    width: 18px;
    height: 18px;
    background: svg-load("../../img/svg/icons/info.svg") 50%/100% no-repeat;
  }
  .icon {
    width: 16px;
    height: 16px;
    background: svg-load("../../img/svg/icons/info.svg") 50%/100% no-repeat;
  }
  .txt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0 4px;
    color:$color-gray;
    font-size:12px;
    letter-spacing:-1px;
    min-height:18px;
    line-height:20px;
    .icon {
      display: inline-block;
    }
  }
  .title {
    display: block;
    position: relative;
    margin-top: 10px;
    padding-left: 23px;
    height: 24px;
    color: $color-gray;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    .icon {
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
  .list {
    padding: 0 0 10px 23px;
    li {
      position: relative;
      padding-left: 6px;
      color: #878d95;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: -1px;
      &::before {
        position:absolute;
        top:10px;
        left:0;
        width:2px;
        height:2px;
        background:#878d95;
        content: "";
      }
    }
  }
  a {
    color:$color-primary;
    text-decoration:underline;
  }
}
.htab_common + .basic_tbl{ 
  margin-top: 36px;
}
.list_btn {
  overflow: hidden;
  margin-top: 10px;
  text-align: center;
  &.v2 {
    margin-top: 38px;
  }
  .btn_blank {
    min-width: 90px;
  }
  .common_btn {
    min-width: 90px;
  }
}

/* mypage */ 
.mypage {
  padding: 50px 0 205px;
  background: #f8f9fa;
  .h3_line + .guide_txt {
    margin-top: 36px;
    margin-bottom: 28px;
    strong {
      font-weight: 700;
    }
  }
  .text_tit {
    display: block;
    margin-bottom: 10px;
    color: $color-black;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -1px;
  }
  h6.text_tit {
    margin-top: 30px;
    font-size: 14px;
  }
  .list_btn {
    margin-top: 20px;
    .btn_blank,
    .btn_full {
      min-width: 124px;
      height: 42px;
    }
    .btn.callcenter {
      padding:4px 11px;
      min-width: 150px;
      border-color:$color-black;
      line-height:16px;
      &:hover{
        background:$color-white;
      }
    }
  }
  .basic_tbl_v2 table tbody td:last-child {
    border-right: 0 !important;
  }
}

/* 예매확인/취소 상세>유의사항 */
.tbl_notice {
  table {
    width: 100%;
    border-top: 1px solid #cfd0d7;
    border-bottom: 1px solid #ecedf2;
    color: $color-gray;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -1px;
    background: $color-white;
  }
  th {
    padding: 10px 18px;
    border-top: 1px solid #ecedf2;
    font-weight: 400;
    text-align: left;
    border-right: 1px solid #ecedf2;
    background: #f8f9fa;
    &.tc {
      padding: 0;
      text-align: center;
    }
  }
  td {
    padding: 10px 18px;
    border-top: 1px solid #ecedf2;
    background: $color-white;
  }
  thead th {
    background: #f8f9fa;
    font-weight: 400;
    text-align: center;
  }
  tr:first-of-type {
    th, td {
      border-top: 0px;
    }
  }
  li {
    position: relative;
    padding-left: 6px;
    font-size: 13px;
    &:before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 2px;
      height: 2px;
      background: #878d95;
      content: '';
    }
  }
  .type_number li {
    padding-left: 10px;
    text-indent: -10px;
    &:before {
      display: none;
    }
  }
  &+.tbl_notice {
    margin-top: 20px;
  }
}

/* 회원 탈퇴 */
.mber_cont {
  display: flex;
  gap: 0 30px;
  margin: 60px 30px 0;

  .widthdraw {
    &_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 326px;
      padding: 47px 20px 40px;
      background: #f8f9fa;
      text-align: center;
      vertical-align: top;
      box-sizing: border-box;
      .btn_full {
        margin-top: auto;
        width: 120px;
        height: 42px;
      }
    }
    &_title {
      display: block;
      position: relative;
      color: $color-black;
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
  
      .logo {
        display: inline-flex;
        margin-top: 2px;
        height: 21px;
        vertical-align: top;
        background-size: auto 21px;
        &.tickelink {
          width: 106px;
          background-image: svg-load('../../img/svg/common/logo_ticketlink.svg');
        }
        &.payco {
          width: 74px;
          background: svg-load('../../img/svg/common/logo_payco.svg') 50%/100% no-repeat;
        }
      }      
    }
    &_desc {
      margin: 40px 0 25px;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      color: #878d95;
    }
  }
}
.mber_cont.leave_members {
  flex-flow: column wrap;
  gap: 0;
  .mberdsc {
    padding: 20px 0 19px;
    color: $color-black;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    background: #f5f6f9;
    em {
      color: $color-primary;
    }
  }
  .mberoutbx {
    margin-top: 30px;
    dd {
      padding: 26px;
      border: 1px solid #ecedf2;
      border-radius: 2px;
    }
    .outbxcon {
      li {
        position: relative;
        padding-left: 4px;
        color: $color-black;
        font-size: 14px;
        line-height: 24px;
        &::before {
          position: absolute;
          width: 2px;
          height: 2px;
          top: 12px;
          left: 0;
          background: $color-black;
          content: "";
        }
      }
    }
  }
 
  .mberguide {
    margin-top: 32px;
    dt {
      display: none;
    }
    dd {
      border: 1px solid $color-black;
      border-radius: 2px;
    }
    .mberlst {
      padding: 21px 29px 14px;
      line-height: 24px;
      li {
        position: relative;
        padding-left: 4px;
        color: $color-primary;
        font-size: 14px;
        line-height: 24px;
        &::before {
          position: absolute;
          top: 12px;
          left: 0;
          width: 2px;
          height: 2px;
          background: $color-primary;
          content: "";
        }
      }
    }
  
    dd p {
      padding: 25px 29px 24px;
      color: $color-gray;
      font-size: 12px;
      letter-spacing: -1px;
      background: #f8f9fa;
      a {
        color: $color-primary;
        text-decoration: underline;
      }
    }
  }
  .mberck {
    margin-top: 20px;
  }
  .btn_full {
    height: 42px;
  }
}
.ly_pop_wrap.ly_member_leave {
  z-index: 200;
  width: 100%;
  height: 100%;
  .layer {
    display: block;
    top: 50%;
    left: 50%;
    z-index: 210;
    margin: -182px 0 0 -205px;
    padding: 45px 25px 23px;
    width: 505px;
    box-sizing: border-box;
    .member_leave {
      font-size: 14px;
      line-height: 24px;
    }
    .txt_deco {
      color: $color-primary;
      font-weight: 700;
    }
  }
  .btn_blank {
    float: right;
    margin-top: 40px;
    width: 101px;
  }
}

/* 취소표대기내역 */
.h3_line {
  a.wait_guide {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 163px;
    border-color: #f56c36;
    color: #f56c36;
    &:hover {
      border-color: #f56c36;
    }
    &:before {
      display: inline-flex;
      margin: auto 3px auto 0;
      width: 16px;
      height: 16px;
      vertical-align: top;
      background: svg-load("../../img/svg/icons/info_orange.svg") 50%/100% no-repeat;
      content: "";
    }
  }
}
.sort_area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .sortbx {
    margin: 0;
  }
}
.sortbx {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
  font-size: 12px;
  .selectbox {
    margin-right: 2px;
    min-width: auto;
    max-width: 100px;
    .select {
      padding-top: 4px;
      padding-bottom: 4px;
      height: 28px;
      font-size: 12px;
    }
  }
  dl {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    dt {
      margin-right: 8px;
      max-width: 72px;
      font-size: 13px;
      word-break: break-word;
    }
    dd {
      display: flex;
    }
  }
  .daysort {
    display: inline-flex;
    overflow: hidden;
    padding-left: 1px;
    border-radius: 2px;
    vertical-align: top;
    li {
      flex: 1;
      a {
        display: block;
        margin-left: -1px;
        width: 62px;
        height: 28px;
        border: 1px solid #ecedf2;
        box-sizing: border-box;
        color: $color-black;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
      }
      &.on a {
        position: relative;
        margin-right: -1px;
        border-color: $color-black;
        color: $color-white;
        background: $color-black;
      }
    }
  }
  .monthsortbx {
    .btn {
      width: 77px;
      height: 28px;
      border-color: $color-black;
      font-size: 13px;
    }
  }
  &:first-child:last-child {
    flex: 1;
  }
}
.ticket_box {
  letter-spacing: -0.42px;
  .ticket_item {
    position: relative;
    min-height: 189px;
    margin-top: 8px;
    padding: 15px 124px 15px 130px;
    border: 1px solid #ecedf2;
    box-sizing: border-box;
    &:before {
      position: absolute;
      top: 0;
      left: 130px;
      width: 1px;
      height: 100%;
      background-color: #e6e6e6;
      content: "";
    }
    &:first-of-type {
      margin: 0;
    }
    .thumb_box {
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 0;
      width: 130px;
      height: 182px;
      transform: translateY(-50%);
      &.type_poster img {
        display: block;
        width: 100%;
        height: 100%;
      }
      &.type_emblem_1 {
        font-size: 0;
        &:before {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          content: "";
        }
        img {
          display: inline-block;
          margin: 0 10px;
          width: 110px;
          font-size: 1px;
          vertical-align: middle;
        }
      }
      &.type_emblem_2 {
        &:before {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          content: "";
        }
        img {
          position: absolute;
          width: 74px;
          height: 74px;
        }
        .emblem_1 {
          top: 9px;
          left: 7px;
        }
        .emblem_2 {
          bottom: 9px;
          right: 7px;
        }
        .vs {
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 14px;
          font-weight: 700;
          color: #a7acb6;
          transform: translate(-50%, -50%);
          cursor: default;
        }
      }
    }
    .info_box {
      padding-left: 20px;
    }
    .prdt_info {
      min-height: 76px;
    }
    .league_tit {
      overflow: hidden;
      max-height: 34px;
      margin-bottom: 4px;
      padding-right: 65px;
      font-size: 13px;
      line-height: 17px;
    }
    .prdt_tit {
      overflow: hidden;
      max-height: 38px;
      margin-bottom: 2px;
      padding-right: 100px;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }
    .date {
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
    }
    .ticket_info {
      color: #878d95;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
    .btn {
      position: absolute;
      top: 24px;
      right: 25px;
      width: 100px;
      padding: 4px 11px;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      &.btn_wait {
        border-color: #ff8700;
        color: $color-white;
        background: #ff8700;
        &:hover {
          border-color: #d47000;
          background: #d47000;
        }
      }
    }
  }
  .txt_orange {
    color: #f56c36;
  }
  .common_data_none {
    margin-top: 140px;
    .text {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

/* 예매확인/취소 > 예매한 예매권(게임권/쿠폰) 정보 */
.basic_tbl.using_condition td{vertical-align:middle}
.using_condition .layer{width:400px;box-sizing:border-box}
.using_condition .layer dt, 
.using_condition .layer dd{margin-top:7px}
.using_condition .layer dt{width:24%;text-align:left;position: relative;padding-left: 4px;}
.using_condition .layer dt:first-child{margin-top:0}
.using_condition .layer dt:before{margin-right:4px;content:'';position: absolute;width: 2px;height: 2px;border-radius: 50%;background: #000;top: 50%;left: 0;transform: translateY(-50%);}
.using_condition .layer dd{overflow:hidden}

/* 예매 확인/취소 복사하기 */
.copy_bx .code{color:$color-primary;line-height:18px;vertical-align:middle;word-break:break-all}
.copy_bx .btn_blank{display:inline-block;width:auto;margin-left:6px}
.copy_bx .btn_copy:hover .copy{border-color:$color-black;background:#f5f6f9}

/* 결제정보 */
.basic_tbl_v4 .final_option .final_list {line-height:22px}

/* 부가정보 */
.btn_txt_bx {margin-top:20px;font-size:0;text-align:center}
.btn_txt_bx .btn {display:inline-block;min-width:124px;height:42px;padding:8px 12px;border:1px solid $color-black;border-radius:6px;background:$color-black;font-size:14px;font-weight:500;line-height:22px;color:$color-white;text-align:center;box-sizing:border-box}
.btn_txt_bx .btn:hover {border-color:#000;background:#000}
.btn_txt_bx .btn:first-child {margin:0}