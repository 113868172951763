@keyframes scroll_ani {
	0% {
		bottom: 30px;
	}
	100% {
		bottom: 50px;
	}
}
.special_exhibit {
	position: relative;
	padding-bottom: 0;
	.header_tab_wrap {
		min-height: 120px;
		.common_tab {
			position: relative;
			z-index: 40;
			padding: 26px 0 35px;
			width: 100%;
			background: #fff;
			&_area {
				overflow: hidden;
				position: relative;
				margin: 0 auto;
				padding-top: 14px;
				max-width: 1120px;
			}
			&_list {
				display: flex;
				flex-wrap: wrap;
				gap: 20px 6px;
				max-height: 40px;
				margin: 0;
				padding-right: 40px;
			}
			&_item {
                position: relative;
                margin: 0;
                &_badge {
                    position: absolute;
                    top: -13px;
                    right: 0;
                    width: 50px;
                    height: 24px;
                    background: url(../img/plan/bg_badge.png) no-repeat;
                    background-size: 50px auto;
                }
			}
            &_btn {
                white-space: nowrap;
                &:disabled {
                    color: #dcdde1;
                    background-color: #fff;
                }
            }
		}
		.tab_add_view_btn {
			position: absolute;
			top: 14px;
			right: 0;
			z-index: 1;
			width: 40px;
			height: 40px;
			border: 1px solid #dcdde1;
			border-radius: 50%;
			&:after {
				display: inline-block;
				width: 100%;
				height: 100%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				content: "";
			}
		}
        &.active {
            .common_tab {
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0 26px 29px 0 rgba(0, 0, 0, 0.10);
                &_list {
                    max-height: none;
                }
            }
            .tab_add_view_btn {
                transform: rotate(-180deg);
            }
        }
	}
	.main_banner_wrap {
		position: relative;
		.dimmed {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.6);
			opacity: 1;
            &_notice {
                position: absolute;
                left: 50%;
                top: 50%;
                font-weight: 700;
                font-size: 40px;
                line-height: 80px;
                color: #fff;
                white-space: nowrap;
                transform: translate(-50%,-50%);
            }
		}
		.bg {
			position: relative;
			margin: 0 auto;
			max-width: 1920px;
			>a {
				display:block;
			}
			img {
				width: 100%;
			}
		}
	}
	.popup_btn_wrap {
		position: absolute;
		right: 40px;
		top: 40px;
		z-index: 1;
		.product_share_btn {
			position: relative;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
			background-color: rgba(255,255,255, 0.95);
		}
		.common_popup_wrap {
			top: 55px;
			left: initial;
			right: 0;
			margin-top: 0;
		}
	}
    .agreement {
        &_frame {
            background-color: #313131;
        }
        &_wrap {
            display: flex;
            justify-content: center;
            padding: 19px 0;
            .checkbox_item {
				position: relative;
                label {
                    color: #fff;
                    letter-spacing: -0.56px;
                }
            }
        }
        &_detail {
            margin-left: 5px;
            color: #fa2828;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.56px;
            text-decoration: underline;
        }
    }
	
    .notice {
        &_frame {
            background-color: #f5f5f5;
        }
        &_wrap {
            display: flex;
            margin: 0 auto;
            max-width: 1120px;
            padding: 60px 0;
            h3 {
                flex: 0 0 150px;
                margin-top: 5px;
                font-weight: 500;
                font-family: 'Roboto';
                font-size: 24px;
                letter-spacing: -0.96px;
            }
            
        }
        &_list {
            color: #242428;
            li {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.64px;
                &+li {
                    margin-top: 6px;
                }
            }
        }
    }
	
	.coupon_list_wrap {
		margin: 90px 0 100px;
		.coupon_heading {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 auto 49px;
			max-width: 1120px;
			font-size: 40px;
			font-weight: 700;
			line-height: 44px;
			letter-spacing: -1.2px;
			&::before {
				margin-bottom: 17px;
				color: #fa2828;
				font-family: 'Roboto';
				font-style: italic;
				font-weight: 500;
				font-size: 22px;
				line-height: 32px;
				letter-spacing: -0.22px;
				text-shadow: 2px 0 rgba(250,40,40,.2);
				content: "COUPON";
			}
		}
		.coupon_img_lst {
			display: flex;
			justify-content: center;
			gap: 0 29px;
			margin: 0 auto;
			padding: 0 30px;
			text-align: center;
		}
		.coupon_img_item {
			width: 400px;
			height: 245px;
			background: url(../img/plan/bg_coupon_black.png) no-repeat;
		}
		.coupon_txt_box {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 360px;
			height: 143px;
			padding: 28px 20px 0;
			color: #fff;
			p {
				@include multi-ellipsis(2);
				max-height: 40px;
				color: #fff;
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				text-align: center;
				opacity: .6;
			}
		}
		.price_wrap {
			display: flex;
			overflow: hidden;
			align-items: center;
			gap: 0 7px;
			margin-top: 18px;
			height: 73px;
			em {
				display: inline-flex;
				font-weight: 700;
				font-family: 'Roboto';
				font-size: 80px;
				>span {
					font-size: 50px;
					line-height: 83px;
					align-self: flex-end;
				}
			}
			.txt {
				font-family: 'ONE-Mobile-Title';
				font-size: 48px;
				font-weight: 400;
				letter-spacing: -1.44px;
			}
			.unit {
				margin-top: 4px;
				width: 52px;
				height: 52px;
				background-repeat: no-repeat;
				background-size: 52px auto;
				&.won {
					background-image: url(../img/plan/bg_won_white.png);
				}
				&.per {
					background-image: url(../img/plan/bg_percent_white.png);
				}
			}
		}
		.coupon_download_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 74px;
			&:before {
				display: block;
				width: 38px;
				height: 38px;
				background: url(../img/plan/ico_download_white.png) no-repeat;
				background-size: 38px auto;
				content: "";
			}
		}
		.coupon_type_02 {
			.coupon_img_item {
				background-image: url(../img/plan/bg_coupon_white.png);
			}
			.coupon_txt_box {
				color: #242428;
				p {
					color: #242428;
				}
			}
			.price_wrap {
				.unit {
					&.won {
						background-image: url(../img/plan/bg_won_black.png);
					}
					&.per {
						background-image: url(../img/plan/bg_percent_black.png);
					}
				}
			}
			.coupon_download_btn {
				&:before {
					background-image: url(../img/plan/ico_download_black.png);
				}
			}
		}
		.coupon_noti {
			display: block;
			margin-top: 40px;
			color: #727984;
			font-size: 16px;
			line-height: 32px;
			font-weight: 400;
			text-align: center;
			&_line {
				color: #727984;
				text-decoration: underline;
			}
		}

	}
    .coupon_schedule {
        &_wrap {
            margin: 100px auto 150px;
            width: 1120px;
            text-align: center;
            .progress {
                position: relative;
                margin-top: 110px;
                height: 62px;
                text-align: left;
                &_tit {
                    position: absolute;
                    left: 0;
                    bottom: 15px;
                    font-family: 'Pretendard';
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 30px;
                }
                &_bg {
                    margin-top: 5px;
                    height: 6px;
                    background-color: #F4F4F4;
                }
                &_active {
                    position: relative;
                    width: 100%;
                    max-width: 1120px;
                    height: 6px;
                    background-color: #000;
                    transition: all 0.5s;
					&::before {
                        position: absolute;
                        left: calc(100% - 10px);
                        top: -27px;
                        z-index: -1;
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        background-color: #000;
                        clip-path: polygon(0% 80%, 80% 0%, 100% 100%);
                        transform: rotate(45deg);
                        content: "";
                    }
                }
                &_board {
                    position: absolute;
                    left: 100%;
                    top: -52px;
                    padding: 8px 15px;
                    width: auto;
                    height: 39px;
                    min-width: 189px;
                    border-radius: 2px;
                    box-sizing: border-box;
                    background-color: #000;
                    color: #fff;
                    font-family: 'Roboto';
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 22px;
                    letter-spacing: 2px;
                    text-align: center;
                    transition: all 0.5s;
                    white-space: nowrap;
                    transform: translateX(-50%);
                }
            }
            .countdown {
                display: flex;
                gap: 0 90px;
                width: 750px;
                height: 120px;
                margin: 52px auto 0;
                &_bx {
                    position: relative;
                    width: 120px;
                    height: 120px;
                    background-color: #242428;
                    float: left;
                    border-radius: 20px;
                    text-align: center;
                    padding: 17px 0;
                    box-sizing: border-box;
                    .number {
                        display: block;
                        color: #fff;
                        font-family: 'Roboto';
                        font-weight: 600;
                        font-size: 60px;
                        line-height: 60px;
                        letter-spacing: -3px;
                    }
                    .txt {
                        display: block;
                        margin-top: 6px;
						padding-left: 2px;
                        color: #fff;
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 2px;
                    }
                    &::before {
                        position: absolute;
                        top: calc(50% - 20px);
                        left: -50%;
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        box-shadow: 0 30px 0#242428;
                        font-size: 0;
                        background: #242428;
                        content: ":";
                    }
                    &:first-child {
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
        &_tit {
            display: block;
            font-weight: 700;
            font-size: 40px;
            line-height: 44px;
        }
        &_date {
            display: block;
            margin-top: 27px;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 10px;
        }
    }
	
	.bottom_banner_wrap {
		overflow: hidden;
		text-align: center;
		>a {
			display: inline-block;
			vertical-align: top;
		}
		img {
			width: 100%;
			max-width: 1920px;
		}
	}
	.product_list_wrap {
		overflow: hidden;
		margin-top: 120px;
		padding-bottom: 120px;
		width: 100%;
		.section_heading {
			margin-bottom: 0;
		}
		.section_title {
			flex: 0 0 auto;
			position: relative;
			z-index: 1;
			margin: 0 auto;
			font-weight: 700;
			font-size: 40px;
			line-height: 58px;
			text-align: center;
			&:before {
				position: absolute;
				right: -5px;
				bottom: 4px;
				left: -5px;
				z-index: -1;
				height: 18px;
				background: var(--bgColor, transparent);
				content: "";
			}
		}
		.addview_btn {
			display: block;
			position: relative;
			margin: 90px auto 30px;
			padding: 0 20px;
			min-width: 140px;
			height: 50px;
			border: 1px solid #242428;
			border-radius: 100px;
			text-align: center;
			>span {
				font-size: 18px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: -0.72px;
				vertical-align: middle;
			}
			.common_icon {
				width: 24px;
				height: 24px;
			}
		}
        &+.product_list_wrap {
            margin-top: 0;
        }
        &.bg_gray {
            margin-top: 0;
            padding-top: 120px;
            background-color: #f6f6f6;
            &+.bg_gray {
                padding-top: 0;
            }
        }
	}
	.list_wrap {
		margin-top: 70px;
	}
	.product_grid {
		&_list {
			margin: 0;
			gap: 70px 20px;
		}
		&_item {
			width: 208px;
			margin: 0;
		}
		&_unit {
			.product_imgbox {
				width: 208px;
				height: 276px;
			}
		}
		.product_info {
			margin-top: 10px;
			.product_region {
				display: block;
				font-size: 12px;
				line-height: 20px;
				color: #707070;
				letter-spacing: -0.36px;
			}
			.product_title {
				margin-top: 4px;
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.48px;
			}
			.product_period {
				height: 22px;
				color: #62676c;
				font-size: 12px;
				line-height: 22px;
				letter-spacing: -0.36px;
			}
			.product_period.d_day {
				color: #fa2828;
			}
		}
		.product_flag {
			margin-top: 8px;
			.flag_area {
				margin: 0;
			}
			.flag {
				margin: 0;
				padding: 5px 10px;
				border-radius: 0;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				vertical-align: top;
				+ {
					.flag {
						margin-left: 6px;
					}
				}
			}
		}
		.event_link {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
			padding: 10px;
			background: #E5EEFB;
			&.gift {
				background: #FBF5E5;
			}
			&.invite {
				background: #E5EEFB;
			}
			&.entry {
				background: #EDE1F2;
			}
			.flag_tit {
				font-size: 12px;
				line-height: 16px;
				font-weight: 400;
				color: #242428;
				@include ellipsis;
				span {
					color: #fa2828;
					font-weight: 700;
				}
			}
			.icon_arrow_right {
				flex: 0 0 auto;
				width: 16px;
				height: 16px;
			}
		}
	}
	.swipe_wrap {
		position: relative;
		margin-top: 40px;
		.product_grid {
			&_list {
				gap: 0;
			}
			&.type_slide {
				.product_grid_list {
					padding-top:34px;
				}
			}
		}
		.common_nav_btn {
			position: absolute;
			top: 155px;
			border: 0;
			background-color: transparent;
			.common_icon {
				width: 24px;
				height: 24px;
			}
		}
		.swiper-button {
			&-prev,
			&-next {
				&::before,
				&::after {
					content: none;
				}
			}
			&-prev {
				left: -50px;
			}
			&-next {
				right: -50px;
			}
		}
		.swiper-pagination {
			top: 0;
			right: 0;
			bottom: inherit;
			left: inherit;
			width: auto;
			&-bullet {
				margin-left: 10px;
				padding: 2px 0;
				width: 24px;
				height: 24px;
				box-sizing: border-box;
				color: #d2d2d2;
				font-family: 'Roboto';
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				background-color: inherit;
				opacity: 1;
				&-active {
					color: #fff;
					background-color: #000;
				}
			}
		}
	}
	.sport_match_schedule {
		margin: 70px 0 0;
		width: 100%;
	}
	.match_card_list {
		display: flex;
		flex-wrap: wrap;
		gap: 26px;
		.match_card {
			margin: 0;
			box-shadow: none;
			border-color: #DCDDE1;
			&_visual {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 142px;
				>img {
					width: auto;
					max-height: 82px;
				}
			}
			&_info {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				height: 118px;
				box-sizing: border-box;
			}
			&_title {
				display: block;
				box-sizing: border-box;
				color: #242428;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
				letter-spacing: -0.8px;
				>span {
					&:first-child {
						&:nth-last-child(2) {
							display: block;
							margin-bottom: 4px;
						}
					}
				}
			}
			&_date {
				min-width: 190px;
				color: #242428;
				font-weight: 400;
				font-size: 16px;
				letter-spacing: -0.64px;
			}
			&_place {
				margin-top: 0;
				min-width: 190px;
				color: #242428;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: -0.48px;
			}
			&_btnarea {
				padding-top: 0;
			}
		}
        &.row_wrap {
            flex-flow: column nowrap;
            gap: 18px 0;
			.match_team_group {
                .match_team_imgbox {
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    background-color: #f5f6f9;
                }
                .icon_versus {
                    margin: 0 6px;
                    &::after {
                        background-image: url(../img/plan/txt_versus.png);
                    }
                }
            }
            .match_card {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                min-height: 132px;

				&_visual {
					flex: 0 0 262px;
					height: 132px;
					>img {
						max-height: 72px;
					}
				}
				&_info {
					flex: 1;
					align-items: flex-end;
					padding-right: 0;
					min-width: 276px;
					height: auto;
					text-align: left;
					&:before {
						content: none;
					}
				}
				&_title {
					position: absolute;
					top: 50%;
					left: 20px;
					max-width: 300px;
					font-size: 22px;
					line-height: 26px;
					transform: translateY(-50%);
				}
				&_date {
					width: 242px;
				}
				&_place {
					width: 242px;
				}
				&_btnarea {
					width: 239px;
					padding: 0 32px;
					box-sizing: border-box;
					.common_btn {
						padding: 0 12px;
						height: 42px;
						font-weight: 500;
						font-size: 14px;
						line-height: 22px;
					}
				}
            }
        }
	}
	.type_div {
		display: flex;
		gap: 0 40px;
		justify-content: center;
		position: relative;
		margin: 0 auto;
		max-width: 1920px;
		min-width: 1290px;
		min-height: 550px;
		.column {
			flex: 1;
			&:nth-child(1) {
				overflow: hidden;
				position: sticky;
				top: 0;
				right: 0;
				z-index: 1;
				width: 100%;
				height: 100vh;
				max-width: 690px;
				min-width: 690px;
			}
			&:nth-child(2) {
				position: relative;
				margin-right: 22px;
				max-width: 1120px;
			}
		}
		.scroll_wrap {
			overflow: hidden;
			overflow-y: scroll;
			width: 100%;
			height: 100%;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		.notice_wrap {
			flex-flow: column wrap;
			gap: 12px 0;
			padding: 32px 32px 40px 40px;
			h3 {
				flex-basis: auto;
			}
			.notice_item {
				&::before {
					top: 10px;
				}
			}
		}
		.popup_btn_wrap {
			position: absolute;
			top: 15px;
			right: 0;
			z-index: 40;
			width: 40px;
			height: 40px;
			.product_share_btn {
				box-shadow: none;
			}
		}
		.product_list_wrap {
			margin-top: 0;
			padding: 35px 0 110px;
			.common_inner {
				width: 100%;
			}
			.section_heading {
				margin-top: -20px;
			}
			.section_title {
				margin-left: 0;
				font-size: 28px;
				line-height: 40px;
				text-align: left;
			}
			.list_wrap {
				margin-top: 25px;
				box-sizing: border-box;
			}
		}
		.product_grid_item {
			flex: 0 1 calc(20% - 16px);
			min-width: 0;
			width: auto;
		}
		.product_imgbox {
			padding-top: 132%;
			min-width: 208px;
			min-height: 276px;
			width: 100%;
			height: auto;
			box-sizing: border-box;
			img {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		.sport_match_schedule {
			min-width: 754px;
		}
	}
	.type_html {
		position: relative;
		.popup_btn_wrap {
			display: flex;
			justify-content: flex-end;
			left: 40px;
			margin: 0 auto;
			max-width: 1840px;
			height: 0;
		}
		img {
			display: block;
			margin: 0 auto;
			max-width: 1920px;
		}
	}
	.buttom_btns {
		position: absolute;
		bottom: 0;
		z-index: 200;
		width: 100%;
		min-width: 690px;
		max-width: 690px;
		&.fixed {
			position: fixed;
		}
		.scroll_ani {
			position: absolute;
			left: 50%;
			bottom: 30px;
			transform: translateX(-50%);
			animation: scroll_ani 0.5s alternate linear infinite;
			&::before {
				display: block;
				width: 23.3px;
				height: 34px;
				background: url(../img/plan/ico_scroll.png) no-repeat;
				content: "";
			}
		}
		.coupon_pack {
			position: absolute;
			bottom: 39px;
			right: 39px;
			.popup_btn {
				display: block;
				width: 64px;
				height: 64px;
				border-radius: 50%;
				box-shadow: 0 6px 9px 0 rgba(0,0,0,0.15);
				background-color: #fff;
				&:before {
					display: block;
					margin: auto;
					width: 24px;
					height: 24px;
					background: url(../img/plan/ico_coupon.png)no-repeat;
					content: '';
				}
			}
			.layer_coupon {
				display: none;
				position: absolute;
				bottom: 84px;
				right: 0;
				z-index: 200;
				width: 216px;
				border-radius: 10px;
				box-sizing: border-box;
				box-shadow: 0 6px 9px 0 rgba(0,0,0,0.15);
				font-family: 'Pretendard';
				background: url(../img/plan/bg_coupon_layer.png) 0 1px no-repeat;
			}
			.coupon_heading {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 20px;
				height: 91px;
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
				&:before {
					margin-bottom: auto;
					padding: 3px 7px;
					border-radius: 30px;
					color: #fff;
					font-weight: 700;
					font-size: 8px;
					line-height: 12px;
					background-color: #fa2828;
					content: "COUPON";
				}
			}
			.coupon_img_item {
				display: flex;
				justify-content: space-between;
				padding: 0 10px 0 20px;
				height: 46px;
				align-items: center;
				font-size: 13px;
			}
			.coupon_download_btn {
				padding: 10px;
				&:before {
					display: block;
					width: 18px;
					height: 18px;
					background: url(../img/plan/ico_download_layer.png) no-repeat;
					background-size: 18px auto;
					content: "";
				}
			}
		}
		.coupon_pack.show {
			.popup_btn {
				&:before {
					background-image: url(../img/plan/ico_coupon_active.png);
				}
			}
			.layer_coupon {
				display: block;
			}
		}
	}
	.no_content {
		margin: 0 auto;
		padding: 120px 0 280px;
		width: 1120px;
		color: #62676c;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -.5px;
		text-align: center;
		.tit {
			display: block;
			font-weight: normal;
		}
		.txt {
			margin-top: 24px;
		}
	}
}
.agreement_modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	.thirdparty {
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 460px;
		max-width: 660px;
		border: 1px solid #242428;
		background-color: #fff;
		transform: translate(-50%,-50%);
	}
	.layer_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 11px 21px;
		border-bottom: 1px solid #ecedf2;
		h5 {
			font-weight: 700;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.56px;
		}
	}
	.layer_inner {
		padding: 20px 21px;
		color: #62676c;
		font-size: 13px;
		letter-spacing: -0.52px;
		>p {
			line-height: 25px;
			letter-spacing: -0.52px;
		}
	}
}
@media (max-width:1864px) {
	.special_exhibit {
		.type_div {
			.product_grid_item {
				flex: 0 1 calc(33.33% - 16px);
			}
			.match_card_list.row_wrap {
				padding-right: 22px;
				box-sizing: border-box;
				.match_card {
					&_info {
						align-items: flex-start;
					}
					&_title {
						position: static;
						transform: none;
					}
				}
			}
		}
	}
}
@media (max-width:1440px) {
	.special_exhibit {
		.type_div {
			gap: 0 30px;
			.match_card_list.row_wrap {
				.match_card {
					display: block;
					width: 353px;
					height: 330px;
					&_visual {
						height: 142px;
					}
					.icon_versus {
						margin: 0 20px;
					}
					&_info {
						height: 118px;
						align-items: center;
						padding-right: 20px;
						text-align: center;
						&::before {
							position: absolute;
							top: 0;
							left: 17px;
							right: 17px;
							height: 1px;
							background: #ecedf2;
							content: ""
						}
					}
					&_title {
						max-width: 100%;
						font-size: 18px;
						line-height: 22px;
					}
					&_date,
					&_place {
						width: 100%;
					}
					&_btnarea {
						padding: 0 20px 20px;
						width: 100%;
						.common_btn {
							height: 48px;
							font-size: 18px;
							font-weight: 700;
							line-height: 28px;
						}
					}
				}
			}
		}
	}
}
@media (max-width:1372px) {
	.special_exhibit {
		.type_div {
			.product_grid_item {
				flex: 0 1 calc(50% - 16px);
			}
		}
	}
}