.pagination {
  margin-top: 30px;
  &_list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_item {
    margin: 0 1px;
  }
  &_link,
  &_current {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    @include font-family('Roboto');
    font-size: 14px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }
  &_link {
    background: $color-white;
    color: map-get($color-type, gray-01);
    &:hover,
    &:focus {
      color: $color-black;
    }
  }
  &_current {
    background: $color-black;
    color: $color-white;
    cursor: default;
  }
  &_nav {
    display: block;
    width: 30px;
    height: 30px;
    background: center / 24px no-repeat;
    @include color-filter(map-get($color-line, line-02));
    &:hover,
    &:focus {
      filter: none;
    }
    &_first {
      background-image: svg-load('../../img/svg/icons/arrow_right_double.svg');
      transform: rotate(180deg);
    }
    &_last {
      background-image: svg-load('../../img/svg/icons/arrow_right_double.svg');
    }
    &_prev {
      margin-right: 3px;
      background-image: svg-load('../../img/svg/icons/arrow_right.svg');
      transform: rotate(180deg);
    }
    &_next {
      margin-left: 3px;
      background-image: svg-load('../../img/svg/icons/arrow_right.svg');
    }
  }
}
