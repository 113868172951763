.common_banner {
  overflow: hidden;
  height: 109px;
  border-radius: 10px;
  &_link {
    display: block;
    height: 100%;
  }
  &_img {
    width: 100%;
    height: 100%;
  }
}