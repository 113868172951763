.common_header {
  position: relative;
  background: $color-white;
}
.header_inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding-right: 8px;
  width: $content-width;
  height: 88px;
  box-sizing: border-box;
}
.header_title {
  overflow: visible;
  background: none;
  line-height: 0;
  &_link {
    display: block;
    &::after {
      display: block;
      width: 154px;
      height: 30px;
      background-image: svg-load('../../img/svg/common/logo_ticketlink.svg');
      content: "";
    }
  }
}
.header_search {
  position: relative;
  margin-left: 20px;

  &_box {
    position: relative;
  }
  &_input {
    width: 250px;
    padding: 7px 55px 7px 18px;
    border: 1px solid map-get($color-line, line-04);
    border-radius: 38px;
    background: $color-white;
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
    box-sizing: border-box;
    outline: none;
    caret-color: $color-primary;
    &:focus {
      border-color: $color-black;
    }
    &::placeholder {
      color: map-get($color-type, gray-02);
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
  &_btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 14px;
    line-height: 0;
  }

  &_layer {
    position: absolute;
    top: 41px;
    left: 12px;
    width: 290px;
    border: 1px solid map-get($color-line, line-01);
    border-radius: 2px;
    background: $color-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    z-index: map-get($z-index, popup);
    .search_suggest {
      &_list {
        padding: 8px 0;
      }
      &_link {
        display: block;
        padding: 9px 31px 9px 12px;
        &:hover,
        &:focus {
          background: map-get($color-background, bg-02)
        }
      }
      &_text {
        @include ellipsis;
        font-size: 13px;
        line-height: 18px;
        color: $color-black;
        letter-spacing: -0.04em;
      }
      &_keyword {
        color: $color-primary;
      }
    }
  }
}
.header_banner {
  margin-left: 20px;
  width: 204px;
  height: 44px;
  &_link {
    display: block;
    height: 100%;
  }
  &_img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
.header_util {
  margin-left: auto;
  &_list {
    display: flex;
    gap: 0 12px;
  }
  &_item {
    display: inline-block;
    position: relative;
    z-index: 2;
    .ico_lang {
      display: inline-flex;
      width: 16px;
      height: 16px;
      background: url(../img/icon/ico_lang.png) 0 0 no-repeat;
      background-size: 16px auto;
      &.eng {
        background-position: 0 -20px;
      }
      &.jpn {
        background-position: 0 -40px;
      }
      &.chn {
        background-position: 0 -60px;
      }
    }

    .btn_dropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px 0;
      padding: 10px 6px 9px;
      font-size: 11px;
      .ico_lang {
        margin: 3px 2px 1px;
      }
      &::after {
        content: none;
      }
      &[aria-expanded="true"] + .header_dropdown_content {
        display: block;
      }
    }
    .header_dropdown_content {
      display: none;
      overflow: hidden;
      position: absolute;
      top: 34px;
      left: -13px;
      border: 1px solid $color-black;
      border-radius: 6px;
      background: $color-white;
      .header_language {
        &_item {
          &:first-child,
          &:last-child {
            .header_language_link {
              height: 39px;
            }
          }
        }
        &_link {
          display: flex;
          align-items: center;
          gap: 0 6px;
          padding: 0 25px 0 15px;
          height: 37px;
          font-size: 14px;
          line-height: 18px;
          color: map-get($color-type, gray-01);

          

          &[aria-selected=true],
          &:hover,
          &:focus {
            background: map-get($color-background, bg-01);
            color: $color-black;
          }
        }
      }
    }
  }
  &_link {
    display: flex;
    flex-direction: column;
    padding: 10px 8px 9px;
    color: #242428;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    text-align: center;

    &::before {
      display: block;
      margin: 1px auto 3px;
      width: 20px;
      height: 20px;
      background: url(../img/icon/ico_header.png) no-repeat;
      background-size: auto 20px;
      content: "";
    }
    $icons: (
      login: 0, 
      logout: -20px, 
      signup: -40px, 
      my: -60px, 
      help: -80px
    );
    @each $icon, $x in $icons {
      &.#{$icon}::before {
        background-position: #{$x} 0;
      }
    }
  }
}
.header_gnb {
  position: relative;
  border-top: 1px solid map-get($color-line, line-01);
  border-bottom: 1px solid $color-black;

  &_inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: $content-width + 20px;
    height: 58px;
  }
  &_area {
    display: flex;
    width: 100%;
  }
  &_list {
    display: flex;
    &:first-child {
      margin-left: -13px;
    }
    & + & {
      margin-left: 23px;
      .header_gnb_item {
        &:first-child {
          .header_gnb_link {
            &::before {
              position: absolute;
              top: 50%;
              left: -11px;
              width: 1px;
              height: 14px;
              background: map-get($color-line, line-02);
              transform: translateY(-50%);
              content: '';
            }
          }
        }
      }
    }
  }
  &_item {
    width: auto;
    &:last-child {
      .header_gnb_link {
        &::before {
          display: inline-block;
          margin: 4px 24px 0 0;
          width: 1px;
          height: 14px;
          vertical-align: top;
          background: map-get($color-line, line-02);
          content: '';
        }
      }
    }
  }
  &_link {
    display: block;
    position: relative;
    padding: 18px 13px 16px 12px;
    color: $color-black;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $color-primary;
    }
    &:active {
      font-weight: 700;
      letter-spacing: -0.04em;
    }
    &[aria-current="page"] {
      color: $color-primary;
      font-weight: 700;
      letter-spacing: -0.04em;
    }
  }
  &_nav {
    display: flex;
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    &::before {
      position: absolute;
      top: -4px;
      bottom: -4px;
      z-index: -1;
      width: 100px;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #fff 40%, #fff 100%);
      content: "";
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border: 1px solid #dcdde1;
      border-radius: 50%;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
      background: #fff;
      content: "";
    }
    .common_icon {
      margin: auto;
      width: 6px;
      height: 12px;
      vertical-align: top;
    }
    &.swiper-button-prev {
      left: 8px;
      &::before {
        left: -8px;
        transform: scaleX(-1);
      }
      .common_icon:after {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.85355 11.8403C5.65829 12.0533 5.34171 12.0533 5.14645 11.8403L0.146447 6.38576C-0.0488147 6.17274 -0.0488147 5.82738 0.146447 5.61437L5.14645 0.159821C5.34171 -0.0531931 5.65829 -0.0531931 5.85355 0.159821C6.04882 0.372834 6.04882 0.718197 5.85355 0.931211L1.20711 6.00006L5.85355 11.0689C6.04882 11.2819 6.04882 11.6273 5.85355 11.8403Z' fill='black'/%3E%3C/svg%3E%0A");  
      }
    }
    &.swiper-button-next {
      right: 8px;
      &::before {
        right: -8px;
      }
      .common_icon:after {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.146447 0.15976C0.341709 -0.0532533 0.658291 -0.0532533 0.853553 0.15976L5.85355 5.61431C6.04882 5.82732 6.04882 6.17268 5.85355 6.38569L0.853553 11.8402C0.658291 12.0533 0.341709 12.0533 0.146447 11.8402C-0.0488155 11.6272 -0.0488155 11.2819 0.146447 11.0689L4.79289 6L0.146447 0.931149C-0.0488155 0.718136 -0.0488155 0.372773 0.146447 0.15976Z' fill='black'/%3E%3C/svg%3E%0A");
      }
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
}

.header_lnb {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid map-get($color-background, bg-01);
  background: $color-white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
  z-index: map-get($z-index, nav);
  &.is-active {
    display: block;
  }
  &_list {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: $content-width;
    height: 46px;
  }
  &_item {
    &:first-child {
      margin-left: -10px;
    }
  }
  &_link {
    display: block;
    padding: 12px 10px;
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
    &:hover,
    &:focus {
      color: $color-primary;
    }
    &:active {
      font-weight: 700;
    }
  }
  .lnb {
    &_group {
      margin: 22px auto 0;
      width: 1120px;
      &:last-of-type { 
        margin-bottom: 30px; 
      }
      .tit_group {
        display: block;
        padding-bottom: 8px;
        color: #242424;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
      }
      .header_lnb {
        &_list {
          flex-flow: wrap;
          gap: 2px 20px;
          height: auto;
        }
        &_item {
          &:first-child { margin-left: 0; }
          flex: 0 0 170px;
        }
        &_link {
          display: inline-flex;
          align-items: center;
          gap: 0 2px;
          padding: 0;
          height: 36px;
          color: #242428;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          &.like {
            &::after {
              overflow: hidden;
              flex: 0 0 14px;
              width: 14px;
              height: 14px;
              font-size: 1px;
              line-height: 1px;
              color: transparent;
              background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='14' height='14' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.87572 2.98005C2.43661 2.37822 3.19723 2.04013 3.99033 2.04013C4.78343 2.04013 5.54406 2.37822 6.10495 2.98005L6.9813 3.91984L7.85766 2.98005C8.13357 2.67344 8.4636 2.42887 8.82851 2.26063C9.19342 2.09238 9.5859 2.00383 9.98304 2.00012C10.3802 1.99642 10.774 2.07764 11.1416 2.23906C11.5092 2.40047 11.8431 2.63884 12.124 2.94026C12.4048 3.24167 12.6269 3.6001 12.7773 3.99463C12.9277 4.38916 13.0033 4.81188 12.9999 5.23813C12.9964 5.66439 12.9139 6.08563 12.7572 6.47729C12.6004 6.86896 12.3726 7.22319 12.0869 7.51932L6.9813 13L1.87572 7.51932C1.315 6.91732 1 6.10093 1 5.24969C1 4.39845 1.315 3.58206 1.87572 2.98005Z' fill='%23FA2828'/%3E%3C/svg%3E%0A");
              content: "좋아요";
            }
          }
          &:hover,
          &:focus {
            color: #FA2828;
          }
        }
      }
    }
    &_banner {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin: 30px auto;
      width: 1120px;

      &_item {
        flex: 0 0 178px;
      }
      &_link {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100px;
      }
      &_img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
      }
    }
    &_no_list {
      margin: 0 auto;
      padding: 55px 0;
      width: 1120px;
      color: #62676c;
      font-weight: 400;;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}



