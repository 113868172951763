// main
.section_heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 32px;
  .section_title {
    flex: 1;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    color: $color-black;
  }
}

.hero_banner {
  position: relative;
  height: 660px;
  background: map-get($color-background, bg-01);
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 58px;
    margin-left: -560px;
    width: 93px;
    height: 40px;
    border-radius: 40px;
    background: rgba($color: $color-black, $alpha: 0.2);
    @include font-family('Roboto');
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
  }
  .swiper-pagination-current {
    margin-right: 5px;
    font-weight: 700;
  }
  .swiper-pagination-total {
    margin-left: 3px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 72px;
    height: 72px;
    background: svg-load('../../img/svg/icons/hero_arrow.svg') center / 100% no-repeat;
    transform: translateY(-50%);
    opacity: 0.5;
    z-index: 2;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .swiper-button-prev {
    left: 50%;
    margin-left: -672px;
    transform: translateY(-50%) rotate(180deg);
  }
  .swiper-button-next {
    right: 50%;
    margin-right: -672px;
  }
  .swiper-slide {
    overflow: hidden;
  }
} 
.hero_autoplay_btn {
  position: absolute;
  bottom: 60px;
  right: 50%;
  margin-right: -560px;
  width: 40px;
  height: 40px;
  z-index: 2;
  &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: svg-load('../../img/svg/icons/banner_stop.svg') center / 10px auto no-repeat rgba(36, 36, 36, 0.6);
      border: 2px solid map-get($color-line, line-03);
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
  }
  .svg_timeline {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      stroke-dasharray: 119.381;
      transform: rotate(-90deg);
      animation: timeline 6s linear forwards;
  }
  .sr_off {
      display: none;
  }
  .sr_on {
      display: block;
  }
  &.animation-reset {
      .svg_timeline {
          animation: none;
          stroke-dashoffset: 119.381;
      }
  }
  &.is-stop {
      &::before {
          background-image: svg-load('../../img/svg/icons/banner_play.svg');
          background-position-x: 14px;
      }
      .svg_timeline {
          animation: none;
          stroke-dashoffset: 119.381;
      }
      .sr_off {
          display: block;
      }
      .sr_on {
          display: none;
      }
  }
}
@keyframes timeline {
  0% {
    stroke-dashoffset: 119.381;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.hero_banner_link {
  display: block;
  position: relative;
  height: 660px;
}
.hero_banner_wrap {
  height: 100%;
}
.hero_banner_imgbox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $container-width;
  height: 660px;
  transform: translate(-50%, -50%);
}
.hero_banner_img {
  width: 100%;
  height: 100%;
}
.hero_banner_txtbox {
  margin: 0 auto;
  width: $content-width;
}
.hero_banner_text {
  position: absolute;
  top: 70px;
  width: 500px;
  color: $color-white;
  &.text_black {
      color: $color-black;
  }
}
.hero_banner_category {
  display: block;
  font-size: 18px;
  line-height: 26px;
}
.hero_banner_title {
  @include multi-ellipsis(2);
  margin-top: 8px;
  max-height: 128px;
  font-size: 50px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.04em;
  white-space: pre-line;
}
.hero_banner_subtit {
  @include multi-ellipsis(2);
  margin-top: 14px;
  max-height: 48px;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;
  white-space: pre-line;
}
.hero_banner_maintxt {
  display: block;
  margin-top: 30px;
  @include font-family('Roboto');
  font-size: 16px;
  line-height: 22px;
}
.hero_banner_subtxt {
  display: block;
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px;
}

.section_category {
  padding: 60px 0;
  .category {
    &_list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    &_item {
      overflow: hidden;
      width: 265px;
      height: 120px;
      border-radius: 4px;
      &_link {
        display: flex;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background: rgba(0,0,0,0.4);
          content: "";
        }
        .text {
          color: #fff;
          font-weight: 700;
          font-size: 22px;
        }
      }
    }
  }
}

// Popular events
.section_today_recommend {
  margin-top: 108px;
  width: auto;
  .section_heading {
    width: $content-width;
    margin: 0 auto 34px;
  }
  .today_recommend {
    position: relative;
    height: 466px;
    &_list {
      overflow: hidden;
      position: absolute;
      top: 50%;
      right: 50%;
      z-index: 2;
      margin: 0 -560px 0 0;
      width: 336px;
      height: 355px;
      transform: translateY(-50%);
    }
    &_item {
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $color-white;
        opacity: 0.15;
        content: "";
      }
      &.is-active {
        &::before {
          height: 3px;
          opacity: 1;
        }
      }
    }
    &_btn {
      display: flex;
      width: 336px;
      padding: 20px 0 25px;
      color: $color-white;
      font-size: 18px;
      line-height: 26px;
      text-align: left;
      pointer-events: auto;
      .category {
        flex: 0 0 auto;
        font-weight: 700;
        margin-right: 16px;
      } 
      .text {
        @include ellipsis;
      }
    }
    &_wrap {
      position: relative;
      &:before {
        position: absolute;
        top: 0;
        left:0;
        right:0;
        bottom:0;
        background: rgba(0,0,0,0.2);
        content: "";
      }
      .common_inner {
        position: relative;
        height: 466px;
      }
    }
    &_link {
      display: flex;
      align-items: center;
      position: relative;
      width: 740px;
      height: 100%;
    }
    &_txtbox {
      color: $color-white;
      width: 309px;
    }
    &_category {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &_title {
      @include multi-ellipsis(3);
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.05em;
    }
    &_subtit {
      @include multi-ellipsis(2);
      margin-top: 6px;
      font-size: 16px;
      line-height: 26px;
    }
    &_imgbox {
      overflow: hidden;
      position: absolute;
      top: 50px;
      left: 380px;
      width: 360px;
      height: 444px;
      border-radius: 10px;
      background: url(../img/svg/common/img_default.svg) center / 54.5% no-repeat map-get($color-background, bg-01);
      @include img-border;
      &::before {
        opacity: 0.02;
      }
    }
    &_img {
      width: 100%;
      height: 100%;
    }
    &_pagination {
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: 327px;
      z-index: 1;
      width: 120px;
      height: 26px;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      .swiper-pagination {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0 4px;
        position: static;
      }
      >button {
        position: absolute;
        top: 50%;
        z-index: 10;
        padding: 6px;
        transform: translateY(-50%);
        &.swiper-button-prev {
          left: 0;
        }
        &.swiper-button-next {
          right: 0;
        }
        .common_icon::after {
          @include color-filter($color-white);
        }
      }
    }
  }
}

// Special events
.section_discount_exhibit {
  margin: 138px auto 110px;
  .section_heading {
    height: 38px;
  }
  .exhibit_banner {
    overflow: hidden;
    height: 152px;
    border-radius: 12px;
    &_link {
      display: block;
      height: 100%;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// Products
.section_weekly_culture {
  margin-top: 138px;
  .weekly_culture {
    display: flex;
    gap: 0 35px;
    position: relative;
    &_col {
      .product_grid_list {
        gap: 120px 0;
      }
    }
  }
}

// TICKET SPOT
.section_ticket_spot {
  margin-top: 108px;
  width: auto;
  background: map-get($color-background, bg-01);
  .common_inner {
    position: relative;
    height: 400px;
  }
  .section_heading {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 2;
    .section_title {
      color: $color-primary;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
  }
  .ticket_spot {
    height: 100%;
    .swiper-slide {
      background: map-get($color-background, bg-01);
    }
    .common_nav_box {
      position: absolute;
      bottom: 60px;
      left: 0;
      z-index: 1;
    }
    &_video {
      overflow: hidden;
      position: absolute;
      top: 47px;
      right: 0;
      width: 545px;
      height: 306px;
      border-radius: 12px;
    }
    &_link {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      position: absolute;
      top: 136px;
      left: 0;
      width: 500px;
      height: 110px;
      &:hover,
      &:focus {
        .ticket_spot_title {
          text-decoration: underline;
        }
      }
    }
    &_title {
      @include multi-ellipsis(2);
      max-height: 132px;
      font-weight: 500;
      font-size: 38px;
      line-height: 44px;
      letter-spacing: -0.05em;
      color: $color-black;
    }
    .btn_hyperlink {
      font-size: 17px
    }
  }
}