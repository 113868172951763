.page_heading {
    display: flex;
    flex-flow: column wrap;
    padding-top: 56px;
    margin-bottom: 40px;
    .page_title {
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.04em;
      color: $color-black;
      & + .common_tab.type_underline {
        margin: 30px 0 0 -10px;
      }
    }
  }

.section_list_product {
    padding-bottom: 18px;
    .product_grid_list {
        .product_imgbox {
            height: 464px;
        }
    }
    .product_recommend {
        margin: 46px 0 140px;
        text-align: center;
        .title {
            display: block;
            padding: 42px 0;
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
        }
        .product_grid_list {
            justify-content: center;
        }
    }
}

.common_data_none {
    padding: 74px 0 114px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    .text {
        color: #62676C;
        &>span {
            font-weight:700;
        }
    }
}

.skeleton_container {
  position: relative;
  .ui_skeleton {
    animation-duration: 1.75s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ani_skeleton;
    animation-timing-function: linear;
    background-image: linear-gradient(120deg, rgba(238,239,241,1) 40%, rgba(246,247,248,1) 48%, rgba(246,247,248,1) 52%, rgba(238,239,241,1) 60%);
    background-repeat: no-repeat;
    background-size: 400% auto;
  }
  .skeleton_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 35px;
    .skeleton_item {
      width: 350px;
    }
    .skeleton_img {
      overflow: hidden;
      width: 100%;
      height: 464px;
      border-radius: 10px;
      background-color: #eeeff1;
    }
    .skeleton_txt {
      margin-top: 20px;
      span {
        display: block;
        height: 18px;
        border-radius: 2px;
        background-color: #eeeff1;
        &:nth-child(1) {
          width: 123px;
        }
        &:nth-child(2) {
          margin-top: 8px;
          width: 100%;
          height: 24px;
        }
        &:nth-child(3) {
          margin-top: 10px;
          width: 224px;
        }
        &:nth-child(4) {
          margin-top: 10px;
          width: 200px;
        }
      }
    }
  }
}
@-webkit-keyframes ani_skeleton {
  0% {
    background-position: 250% 0
  }
  100% {
    background-position: -250% 0
  }
}