.search_cont {
  background: #f8f9fa;
}
.detail_box_top {
  margin-bottom: 10px;
  padding: 36px 29px 30px;
  min-height: 530px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 2px;
  background: $color-white;
  zoom: 1;
  &:after {
    display: block;
    clear: both;
    content: "";
  }
  span.num {
    color: $color-primary;
    font-weight: 700;
    font-family: 'Roboto',-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,'Malgun Gothic',Arial,sans-serif;
  }
}
.detail_box_bot {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
  .detailbox_bot_left {
    float: left;
    margin-right: 20px;
    width: 817px;
    border-radius: 2px;
    background: $color-white;
  }
  .detailbox_bot_right {
    overflow: hidden;
    float: right;
    padding: 19px 57px;
    border: 1px solid map-get($color-line, line-01);
    border-radius: 2px;
    text-align: center;
    background: $color-white;
    strong {
      display: block;
      margin-bottom: 19px;
      font-size: 18px;
      text-align: center;
      line-height: 18px;
    }
    li {
      font-size: 14px;
      text-align: left;
      .thum {
        margin-bottom: 10px;
      }
      .title {
        margin-bottom: 5px;
        width: 166px;
        color: $color-black;
        font-weight: 700;
        line-height: 17px;
      }
      .date {
        display: block;
        margin-bottom: 18px;
        color: $color-gray;
        font-family: 'Roboto',-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,'Malgun Gothic',Arial,sans-serif;
      }
    }
  }
}
.search_notice {
  padding: 10px 30px 0;
  border: 1px solid #ecedf2;
  border-radius: 2px;
  background: #fff;
  &_title {
    padding: 16px 20px 8px;
    color: #242428;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  &_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &_item {
    position: relative;
    flex: 0 0 auto;
    padding: 20px 0 30px;
    width: 520px;
    border-bottom: 1px dotted #cfd0d7;
    &:nth-last-child(-n + 2):nth-child(odd) {
      border: 0;
    }
    &:last-child {
      border: 0;
    }
  }
  &_link {
    display: flex;
    gap: 0 17px;
    .imgbox {
      flex: 0 0 auto;
      width: 110px;
      height: 134px;
      >img {
        width: 100%;
      }
    }
    .info {
      overflow: hidden;
      flex: 1;
      padding: 10px 0;
      .title {
        display: flex;
        min-height: 40px;
        align-items: center;
        margin-bottom: 24px;
        font-weight: 700;
        color: #242428;
        font-size: 16px;
        line-height: 20px;
        .ellipsis {
          @include multi-ellipsis(2);
        }
      }
      .text {
        @include ellipsis;
        padding-right: 80px;
        color: #62676C;
        font-size: 13px;
        line-height: 21px;
        &>strong {
          display: inline-block;
          min-width: 50px;
          vertical-align: top;
        }
        &>em {
          font-weight: 700;
          color: #FA2828;
        }
      }
    }
  }
}
.rslt_wrap {
  min-height: 500px;
  padding: 0 0 50px;
  .tx_result {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    em {
      color: #fa2828;
    }
  }
  .lst_srchbest {
    margin-top: 12px;
    letter-spacing: -1px;
    font-size: 14px;
    color: #878d95;
    strong {
      vertical-align: middle;
      font-weight: 400;
      float: left;
      margin-right: 8px;
    }
    ul {
      display: inline-block;
      vertical-align: middle;
      display: inline;
      zoom: 1;
    }
    li {
      float: left;
      margin-right: 8px;
      a {
        color: #878d95;
      }
      span {
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: 3px 0 0 8px;
        background: #878d95;
        vertical-align: top;
      }
    }
  }
  .srh_error {
    margin-top: 2px;
    li {
      float: none;
      position: relative;
      margin: 0;
      padding-left: 7px;
      font-size: 12px;
      color: #62676c;
      line-height: 22px;
      span {
        position: absolute;
        top: 9px;
        left: 0;
        width: 2px;
        height: 2px;
        margin: 0;
      }
    }
  }
  .srchrslt_category {
    display: table;
    overflow: hidden;
    table-layout: fixed;
    margin: 56px 0 0;
    width: 100%;
    li {
      display: table-cell;
      height: 48px;
      border: 1px solid #ecedf2;
      border-left: none;
      line-height: 48px;
      background: #fff;
      &:first-child {
        margin-left: 0;
        border-left: 1px solid #ecedf2;
      }
      a {
        display: block;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        box-sizing: border-box;
      }
      &.on {
        border-color: #242428;
        background: #242428;
        a {
          color: #fff;
        }
      }
    }
  }

  .tit_t_result, 
  .tit_e_result {
    margin-top: 33px;
    font-size: 20px;
    line-height: 19px;
    font-weight: 700;
  }
  .rslt_bx {
    position: relative;
    margin-top: 16px;
    .selectbox {
      position: absolute;
      right: 0;
      top: -39px;
      width: 140px;
      background-color: #fff;
      .select_list {
        z-index: 10;
      }
    }
    .detail_box_top {
      min-height: 135px;
      margin: 0;
      padding: 30px 30px 0;
      box-sizing: border-box;
      .result_lst {
        li {
          position: relative;
          padding: 30px 0;
        
          &:not(:first-child)::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1px;
            border-top: 1px dotted #cfd0d7;
            content: "";
          }
        
          &:first-child {
            padding: 0 0 30px;
            background: none;
          }
        }
      }
      .srh_none {
        padding: 45px 0 74px;
        text-align: center;
        font-size: 14px;
        color: #62676c;
        letter-spacing: -1px;
      }
      .pagination {
        padding-bottom: 40px;
      }
    }
  }
  .result_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding-right: 125px;
    width: 100%;
    min-height: 126px;
    box-sizing: border-box;
    .result_info {
      display: block;
      height: 100%;
      font-size: 0;
    }
    .img_box {
      display: inline-block;
      margin-right: 17px;
      vertical-align: top;
    }
    .bx_tit {
      display: inline-block;
      margin-top: 10px;
      width: 659px;
      vertical-align: top;
      .info_tit {
        display: block;
        font-size: 0;
      }
      .flag_area {
        display: inline-block;
        margin: 3px 0 0 0;
        vertical-align: middle;
        .flag {
          margin: 0 4px 0 0;
        }
      }
      .tit {
        
        display: inline-block;
        overflow: hidden;
        width: 560px;
        color: #242428;
        font-size: 16px;
        line-height: 27px;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: top;
      }
      dl {
        overflow: hidden;
        margin-top: 10px;
        width: 100%;
        dt {
          float: left;
          margin-right: 4px;
          color: #62676c;
          font-weight: 700;
          font-size: 13px;
          line-height: 21px;
        }
        dd {
          overflow: hidden;
          color: #62676c;
          font-size: 13px;
          line-height: 21px;
        }
      }
    }
    .btn {
      position: absolute;
      top: 14px;
      right: 0;
      width: 110px;
    }
  }
  .result_box.sports {
    min-height: 110px;
    padding: 0;
    font-size: 0;
    .result_info {
      display: flex;
    }
    .img_box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0;
      padding: 20px;
      width: 250px;
      height: 110px;
      box-sizing: border-box;
      font-size: 0;
      text-align: center;
      vertical-align: middle;
      background-color: #f5f6f9;
    }
    .ico {
      position: absolute;
      top: 1px;
      left: 1px;
      padding: 12px 0 5px;
      width: 52px;
      height: 38px;
      box-sizing: border-box;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      background: #242428;
      &::after {
        position: absolute;
        left: 0;
        top: 100%;
        border-style: solid;
        border-color: transparent;
        border-top-color: #242428;
        border-width: 12px 26px;
        z-index: 1;
        content: "";
      }
      &.ico_reserve {
        background: #fa2828;
        &::after {
          border-top-color: #fa2828;
        }
      }
    }
    .ico_vs {
      margin: 0 5px;
      width: 22px;
      color: #a7acb6;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
    }
    .bx_tit {
      margin: 0;
      padding: 10px 30px 15px 17px;
      width: 570px;
      box-sizing: border-box;
      vertical-align: middle;
      .tit {
        width: 100%;
        height: auto;
        color: #242428;
        font-size: 15px;
      }
    }
  }
}