.common_btn_wrap {
  display: flex;
  justify-content: center;
}
.common_btn_box {
  display: flex;
  .common_btn {
    flex: 1;
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.common_btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &.btn_primary {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $color-white;
    &:hover,
    &:focus {
      border: 1px solid map-get($color-type, red-01);
      background-color: map-get($color-type, red-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border: 1px solid map-get($color-background, bg-04);
      background-color: map-get($color-background, bg-04);
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
    &.plan_sale {
      &:disabled,
      &[aria-disabled=true] {
        color: $color-black;
      }
    }
  }
  &.btn_secondary {
    border: 1px solid $color-black;
    background-color: $color-black;
    color: $color-white;
    &:hover,
    &:focus {
      border: 1px solid map-get($color-type, black-01);
      background-color: map-get($color-type, black-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border: 1px solid map-get($color-background, bg-04);
      background-color: map-get($color-background, bg-04);
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
  }
  &.btn_ghost {
    border: 1px solid map-get($color-line, line-04);
    background-color: $color-white;
    color: $color-black;
    &:hover,
    &:focus {
      border-color: $color-black;
      background-color: map-get($color-background, bg-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border-color: map-get($color-line, line-04);
      background-color: $color-white;
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
  }
  &.btn_ghost_secondary {
    border: 1px solid $color-black;
    background-color: $color-white;
    color: $color-black;
    &:hover,
    &:focus {
      border-color: $color-black;
      background-color: map-get($color-background, bg-01);
    }
    &:disabled,
    &[aria-disabled=true] {
      border-color: map-get($color-line, line-04);
      background-color: $color-white;
      color: map-get($color-type, gray-02);
    }
    &[aria-disabled=true] {
      pointer-events: none;
    }
  }
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    border: 1px solid map-get($color-background, bg-04);
    background-color: map-get($color-background, bg-04);
    color: $color-black;
    &:disabled,
    &[aria-disabled=true] {
      color: map-get($color-type, gray-02);
      pointer-events: none;
    }
  }
  &.btn_xsmall {
    padding: 0 12px;
    height: 28px;
    font-size: 13px;
    line-height: 18px;
  }
  &.btn_small {
    padding: 0 12px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  &.btn_medium {
    padding: 0 12px;
    height: 42px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  &.btn_large {
    height: 48px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.04em;
  }
  &.btn_xlarge {
    height: 56px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.04em;
  }
}
.btn_hyperlink {
  display: inline-block;
  position: relative;
  font-size: 15px;
  line-height: 22px;
  color: $color-black;
  &::before {
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 1px;
    background: $color-black;
    content: '';
  }
  &[aria-disabled=true] {
    color: map-get($color-type, gray-02);
    pointer-events: none;
    &::before {
      display: none;
    }
  }
}
.btn_dropdown {
  position: relative;
  padding: 6px 13px 6px 0;
  font-size: 13px;
  line-height: 18px;
  color: $color-black;
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    border-style: solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-width: 4.5px 3.5px;
    content: '';
  }
}
.common_nav_box {
  font-size: 0;
  line-height: 0;
}
.common_nav_btn {
  position: relative;
  width: 38px;
  height: 38px;
  border: 1px solid map-get($color-line, line-04);
  border-radius: 6px;
  background: $color-white;
  font-size: 0;
  line-height: 0;
  & + & {
    margin-left: 6px;
  }
  &:hover,
  &:focus {
    border-color: $color-black;
    background: map-get($color-background, bg-01);
  }
  &:disabled {
    border-color: map-get($color-line, line-04);
    &:hover,
    &:focus {
      background: $color-white;
    }
  }
}