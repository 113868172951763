// common tooltip
.tooltip_btn_wrap {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  .btn_tooltip{
    padding: 2px;
    flex: 0 0 auto;
    &[aria-expanded=true] + .common_tooltip_wrap {
      display: block;
    }
  }
}
.common_tooltip {
  &_wrap {
    display: none;
    position: absolute;
    top: calc(100% + 14px);
    left: 50%;
    transform: translateX(-50%);
    z-index: map-get($z-index, popup);
    .common_tooltip {
      min-width: 356px;
      border: 1px solid $color-black;
      border-radius: 2px;
      background: $color-white;
      box-sizing: border-box;
    }
  }
  &_content {
    @include scrollbar;
    box-sizing: border-box;
  }
  &_title {
    display: block;
    margin: 0 20px;
    padding: 11px 0;
    border-bottom: 1px solid map-get($color-line, line-01);
    color: $color-black;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
  &_text {
    padding: 11px 20px;
    min-height: auto;
    color: $color-gray;
    font-size: 13px;
    line-height: 18px;
  }
  &_close {
    position: absolute;
    top: 14px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: svg-load('../../img/svg/icons/close_16.svg') 50%/100% no-repeat;
  }
}